import React from "react";
import BackDrop from "components/ui/backdrop"
import Logo from "components/logo";
import NavigationItems from "components/navigation/navigationItems";
import classes from "./sideDrawer.module.css";

const sideDrawer = (props) => {
    return (
        <React.Fragment>
            <BackDrop onClick={props.close} show={props.show} className={classes.BackDrop} onlyMobile/>
            <div className={[classes.SideDrawer, props.show ? classes.Open : classes.Close].join(' ')}>
                <div className={classes.Logo}>
                    <Logo/>
                </div>
                <nav>
                    <NavigationItems isAuth={props.isAuth} onClick={props.close}/>
                </nav>
            </div>
        </React.Fragment>
    );
};

export default sideDrawer;