import React, { useEffect } from "react";
import doEmpadronamiento from "axios/doEmpadronamiento";
import queryMiddleState from "components/queryMiddleState/queryMiddleState";
import { ERROR_EMPADRONAMIENTO } from "constants/mensajes";
import { Redirect } from "react-router-dom";
import * as ROUTES from "constants/routes";

export default props => {
    const consorcioId = props.match.params.consorcio;
    const uf = props.match.params.uf;
	const [cargandoEmp, dataEmp, errorEmp, ejecutar] = doEmpadronamiento(consorcioId, uf, null);
    useEffect(() => {
        ejecutar(true);
    }, [ejecutar])
	return (
    <React.Fragment>
        {queryMiddleState(
            errorEmp, 
            cargandoEmp, 
            dataEmp, 
            () => onNull(), 
            () => {return onSuccess()})}
    </React.Fragment>
    )
}

const onNull = () => {
    return <div style={{textAlign: "center"}}>{ERROR_EMPADRONAMIENTO}</div>;
}

const onSuccess = () => {
	return (
        <Redirect to={ROUTES.EXPENSAS} push />
	);
}
