import React, { useContext, useState } from 'react';
import classes from './layout.module.css';
import Toolbar from "components/navigation/toolbar";
import SideDrawer from "components/navigation/sideDrawer";
import { Auth } from "components/firebase/authContext";

const Layout = props => {
    const [ sideDrawerIsVisible, setSideDrawerIsVisible ] = useState(false);
    const {usuario} = useContext(Auth);
    return (
        <React.Fragment>
            <Toolbar 
                toogleSideDrawer={() => setSideDrawerIsVisible(!sideDrawerIsVisible)}
                isAuth={usuario}/>
            <SideDrawer 
                show={sideDrawerIsVisible} 
                close={() => setSideDrawerIsVisible(false)}
                isAuth={usuario}/>
            <main className={classes.Content}>
                {props.children}
            </main>
        </React.Fragment>);
}

export default Layout;
