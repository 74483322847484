import React, { useEffect } from "react";
import Liquidacion from "components/liquidacion/liquidacion";
import QueryMiddleState from "components/queryMiddleState/queryMiddleState";
import {LIQUIDACION_NO_ENCONTRADA} from "constants/mensajes";
import getLiquidacion from "axios/getLiquidacion";

export default props => {	
	const expensaId = props.match.params.expensaId;
	const [cargando, data, error, ejecutar] = getLiquidacion(expensaId);
	useEffect(() => {
		ejecutar();
	}, [ejecutar]);

	return QueryMiddleState(error, cargando, data, onNull, () => {return onSuccess(data)});
};

const onNull = () => {
	return (<div>{LIQUIDACION_NO_ENCONTRADA}</div>);
}

const onSuccess = (data) => {
	return (<Liquidacion {...data}/>);
}
