import React from "react";
import classes from "./gastoHeader.module.css";
import PropTypes from 'prop-types';
import calcularGrid from "utils/calcularGrid";
const GastoHeader = (props) => {
	return (
		<div className={classes.GastoRow} style={calcularGrid(props.headers.length)}>
			<div className={[classes.Titulo, classes.Descripcion].join(" ")}>{props.soloHeader ? null : "Descripcion"}</div>
			{props.headers.map((header, key) => { 
				return (<div className={classes.Titulo} key={key}>{header}</div>);
			})}
		</div>
	);
}

GastoHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

export default GastoHeader;

const CuadroHeader = (props) => {
	return (
		<div className={classes.GastoRow} style={calcularGrid(props.headers.length - 1)}>
			{props.headers.map((header, key) => { 
				return (<div className={classes.Titulo} key={key}>{header}</div>);
			})}
		</div>
	);
}

CuadroHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

export {CuadroHeader};
