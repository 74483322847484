import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./periodoCard.css";
import m from "utils/moneda";
import f, {nombreMesYAnio} from "utils/fecha";
import o from "utils/ordinales";
import Tooltip from "components/ui/Tooltip/Tooltip";
import PeriodoRow, { PeriodoRowVencimiento } from "./periodoRow/periodoRow";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Collapse } from '@material-ui/core';
import formasDePago from "constants/formasDePago";
import estadosPago from "constants/estadosPago";
import classes from "./periodoCard.module.css";

export default (props) => {
    const {ufData, toggleDialogoPago, pagosHabilitados} = props;
    const [open, setOpen] = useState(false);
    var titular = ufData.nombre ? ufData.nombre.toLowerCase() : "";
    var pisoDpto = ufData.piso !== null && ufData.dpto !== null ? 
                        ` - Piso: ${ufData.piso} - Dpto: ${ufData.dpto}` :
                        ufData.piso !== null ? ` - ${ufData.piso}` : "";
    
    return (
        <div className={classes.Card}>
            <Link to={`/expensas/${ufData.expensaId}`} className={classes.LinkHeader}>
                <Tooltip title="Ver liquidación" placement="right" arrow>
                        <header className={classes.CardHeader}>
                            <div className={classes.CardHeaderText}>
                                <div>{nombreMesYAnio(ufData.periodo)}</div>
                                <div>{`UF: ${ufData.uf}${pisoDpto}`}</div>
                                <div className={classes.Titular}>{`Titular: ${titular}`}</div>
                            </div>
                            <div className={classes.ArrowRight}></div>
                        </header>
                </Tooltip>
            </Link>
            <div className={classes.CardBody}>
                {ufData.gastos.map((gasto, i) => {
                    return <PeriodoRow titulo={gasto.key} valor={m(gasto.value)} aFavor={gasto.value < 0} key={i}/>
                })}

                {ufData.saldoAnterior !== 0 ?
                    <PeriodoRow titulo={ufData.saldoAnterior > 0 ? "Saldo deudor" : "Saldo a favor"} 
                        valor={m(ufData.saldoAnterior)}
                        overrideClassNameValor= {[classes.RowCardValue, 
                            ufData.saldoAnterior > 0 ? classes.RowCardDeuda : classes.RowCardAFavor].join(" ")}/> : null}

                {ufData.punitorios > 0 ?
                    <PeriodoRow titulo={"Punitorios"} 
                                valor={m(ufData.punitorios)}
                                overrideClassNameValor= {[classes.RowCardValue, classes.RowCardDeuda].join(" ")}/> : null}

                <PeriodoRow titulo="Subtotal" valor={m(ufData.subtotal)}/>
                <hr/>
                {ufData.vencimientos.map((vencimiento, i) => {
                    return <PeriodoRowVencimiento 
                                numVencimiento={`${o(i)}`} 
                                fechaVencimiento={f(vencimiento)} 
                                valor={m(ufData.totales[i])} 
                                key={"Vencimiento"+i} />
                })}
                {ufData.pagos && ufData.pagos.length > 0 ? 
                <React.Fragment>
                    <hr/>
                    <div className={classes.RegistrarPagoContainer} style={{paddingBottom: "0.5rem"}}>
                        <Button
                        disableElevation
                            color="secondary"
                            size="small"
                            className={classes.Button}
                            startIcon={open ? <KeyboardArrowUpIcon fontSize="small"/> : <KeyboardArrowDownIcon fontSize="small" />}
                            onClick={() => {setOpen(!open)}}
                        >{open ? "Ocultar pagos" : "Mostrar pagos"}</Button>
                    </div>
                    <Collapse in={open} timeout="auto" style={{paddingBottom: 0}}>
                        <div className={classes.Pagos} style={{paddingBottom: 0, marginBottom: "1.125rem"}}> 
                            {ufData.pagos.map((pago, key) => {
                                var estadoPago = estadosPago.filter(e => e.id === pago.estadoPago)[0];
                                var estadoPagoStyle = "";
                                if (estadoPago.id === "RECHAZADO")
                                    estadoPagoStyle = classes.EstadoRechazado;
                                else if (estadoPago.id === "PENDIENTE_VERIFICACION")
                                    estadoPagoStyle = classes.EstadoPendiente;
                                else if (estadoPago.id === "VERIFICADO")
                                    estadoPagoStyle = classes.EstadoVerificado;
                                    
                                return <div className={classes.Pago} key={key}>
                                    <div className={classes.Row}>Fecha aviso: <span>{f(pago.fechaAviso.substring(0, 10))}</span></div>
                                    <div className={classes.Row}>Fecha pago: <span>{f(pago.fechaPago.substring(0, 10))}</span></div>
                                    <div className={classes.Row}>Forma de pago: <span>{formasDePago.filter(e => e.id === pago.formaDePago)[0].label}</span></div>
                                    <div className={classes.Row}>Comprobante: <span>{pago.comprobante}</span></div>
                                    <div className={classes.Row}>Importe Abonado: <span>{m(pago.importeAbonado)}</span></div>
                                    <div className={`${classes.Row} ${estadoPagoStyle}`}>
                                        Estado del pago: <span style={{textAlign: "right"}}>{estadoPago.label.toUpperCase()}</span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </Collapse>
                </React.Fragment> : null}
                {pagosHabilitados ?
                    <div className={classes.RegistrarPagoContainer}>
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            className={classes.Button}
                            startIcon={<PlaylistAddIcon />}
                            onClick={() => {toggleDialogoPago(ufData)}}
                        >Registrar Pago</Button>
                    </div>
                : null}
            </div>
        </div>
    );
}
