import { useCallback } from 'react';
import { Wrapperv2 } from './APIv2';

export default () => {
    var wrapper = Wrapperv2(false, "DELETE");
    const {setURLYEjecutar} = wrapper;
    const ejecutar = useCallback(async (edificioId, comunicacionId, then) => {
        return await setURLYEjecutar(`comunicaciones/${edificioId}/${comunicacionId}`, then);
    }, [setURLYEjecutar]);
    return {"cargando": wrapper.cargando, "data": wrapper.data, "error": wrapper.error, "ejecutar": ejecutar};
}
