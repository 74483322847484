import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {config, uiConfig} from "./config"
import { withFirebase } from "./context"
//https://github.com/firebase/firebaseui-web-react/	

class Firebase {
	static instance = null;
	
	static getInstance() {
		if(!this.instance) {
			this.instance = new Firebase();
		}
		return this.instance;
	}
	constructor() {
		this.firebaseApp = app;
		app.initializeApp(config);
		app.auth().languageCode = "es"
		this.auth = app.auth();
		this.database = app.database();
		this.config = config;
		this.uiConfig = uiConfig;
	}
	logOut() {
		this.auth.signOut()
		.then(() => sessionStorage.clear())
		.catch(function(error) {
			console.log("logout con error", error);
		});
	}
}
export default Firebase.getInstance()
export { withFirebase }


