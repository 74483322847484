import React from "react";
import Mensaje from "components/ui/mensaje/mensaje";
import PeriodoCard from "components/periodoCard"

const onSuccessUfs = (data, toggleDialogoPago) => {
	if (!data || !data.ufs || data.ufs.length === 0)
		return mensaje;

	data.ufs.sort((a,b) => (a.periodo + a.uf > b.periodo + a.uf) ? -1 : ((b.periodo + a.uf > a.periodo + a.uf) ? 1 : 0));
	const ultimoPeriodo = data.ufs[0].periodo;
	return (
		<div className="Liquidaciones">
			{ 
				data.ufs.map((expensa, i) => {
					return <PeriodoCard ufData={expensa} key={i} toggleDialogoPago={toggleDialogoPago} pagosHabilitados={data.pagosHabilitados &&ultimoPeriodo === expensa.periodo}/>
				})
			}
		</div>
	);
}

const onNullUfs = (consorcioId) => {
	if (!consorcioId)
		return null;
	return mensaje;
}

const mensaje = (<Mensaje>
	<p>¡Uy! No tenes ninguna unidad funcional registrada para este edificio todavía. Pero no te preocupes, 
		es muy sencillo asociarlo.</p>
	<p><b>Opción 1</b>: Si tenés una liquidación en formato papel, que cuente con un código QR, 
		podés escanearlo con tu celular y se vincula automáticamente.</p>
	<p><b>Opción 2</b>: Elegí tu unidad funcional presionando el botón <b>"ASOCIAR UNIDAD FUNCIONAL"</b> 
	en esta página.</p>
</Mensaje>);

export {onSuccessUfs, onNullUfs};
