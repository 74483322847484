import React from "react";
import { Redirect } from "react-router-dom"
import { withFirebase } from "../../firebase";
import * as ROUTES from "constants/routes";

const SalidaPage = (props) => {
	props.firebase.logOut();
	return <Redirect to={ROUTES.INGRESAR}/>
}

export default withFirebase(SalidaPage);
