import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export default (props) => {
    const LightTooltip = withStyles((theme) => {
      
      var style = {
        arrow: {
            color: theme.palette.common.white,
        },
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          whiteSpace: "pre-wrap",
          fontFamily: "'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        },
      }
      if (props.textoNormal) {
        style.tooltip.fontSize = 16;
      }
      else {
        style.tooltip.fontSize = 12;
      }
      return style;
    })(Tooltip);

    return  <LightTooltip title={props.title} arrow placement={props.placement}>
                {props.children}
            </LightTooltip>
}