import React, { useState } from "react";
import { Badge, Button, Collapse, IconButton } from "@material-ui/core";
import m from "utils/moneda";
import classes from "./Cobranza.module.css";
import f from "utils/fecha";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from "components/ui/Tooltip/Tooltip";
import formasDePago from "constants/formasDePago";
import estadosPago from "constants/estadosPago";
import Spinner from "components/ui/spinner";

export default (props) => {
    const {uf, setUf, setDialogoOpen, verificarPago, rechazarPago, cargandoCambiarEstadoPago, pagosHabilitados} = props;
    const [open, setOpen] = useState(false);
    
    return <div className={classes.Row}>
        <div className={classes.RowHeaderMobile}>
            <div className={classes.MobileHeader}>UF: {uf.uf} {uf.piso ? "- Piso: " + uf.piso : null} {uf.dpto ? "- Dpto: " + uf.dpto : null}</div>
            <div className={`${classes.Cell} ${classes.CellIconos}`}>
                {pagosHabilitados && uf.pagos ? 
                <Badge variant="dot" color="error" invisible={!uf.pagos.some(pago => pago.estadoPago === "PENDIENTE_VERIFICACION")}>
                    <Tooltip title={open ? "Ocultar pagos" : "Mostrar pagos"} arrow>
                        <IconButton aria-label="expandir" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon fontSize="small"/> : <KeyboardArrowDownIcon fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </Badge> : null}
            </div>
        </div>
        <div className={classes.MobileHeader}>Nombre: {uf.nombre ? uf.nombre : ""}</div>
        Saldos:<br/>
        {
            uf.totales.map((total, key) => 
                <div className={classes.Saldo} key={key}>
                    {f(uf.vencimientos[key])} <span className={total < 0 ? classes.SaldoAFavor : null}>{m(total)}</span>
                </div>)
        }
        {pagosHabilitados ?
            <div className={`${classes.Saldo} ${classes.MobileHeader}`}>
                Total pagado <span>{m(uf.totalPagado)}</span>
            </div> 
        : null}
        
        {pagosHabilitados && uf.pagos ? 
            <Collapse in={open} timeout="auto" style={{paddingBottom: 0, marginTop: "-0.5ch", marginBottom: "0.5ch"}}>
                <div className={classes.PagosMobile}> 
                    {uf.pagos.map((pago, key) => {
                        var estadoPago = estadosPago.filter(e => e.id === pago.estadoPago)[0];
                        var estadoPagoStyle = "";
                        if (estadoPago.id === "RECHAZADO")
                            estadoPagoStyle = classes.EstadoRechazado;
                        else if (estadoPago.id === "PENDIENTE_VERIFICACION")
                            estadoPagoStyle = classes.EstadoPendiente;
                        else if (estadoPago.id === "VERIFICADO")
                            estadoPagoStyle = classes.EstadoVerificado;
                            
                        return <div className={classes.PagoMobile} key={key}>
                            <div className={classes.Saldo}>Fecha aviso: <span>{f(pago.fechaAviso.substring(0, 10))}</span></div>
                            <div className={classes.Saldo}>Fecha pago: <span>{f(pago.fechaPago.substring(0, 10))}</span></div>
                            <div className={classes.Saldo}>Forma de pago: <span>{formasDePago.filter(e => e.id === pago.formaDePago)[0].label}</span></div>
                            <div className={classes.Saldo}>Comprobante: <span>{pago.comprobante}</span></div>
                            <div className={classes.Saldo}>Importe Abonado: <span>{m(pago.importeAbonado)}</span></div>
                            <div className={`${classes.Saldo} ${estadoPagoStyle}`}>
                                Estado del pago: <span style={{textAlign: "right"}}>{estadoPago.label.toUpperCase()}</span>
                            </div>
                            
                            {estadoPago.id === "PENDIENTE_VERIFICACION" ?
                                cargandoCambiarEstadoPago ? <Spinner centered /> :
                                <div className={classes.BotonesAccionPago}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        className={classes.Button}
                                        startIcon={<ClearIcon />}
                                        onClick={() => {rechazarPago(uf.id, pago.uuid);}}
                                    >Rechazar</Button>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        className={classes.Button}
                                        startIcon={<CheckIcon />}
                                        onClick={() => {verificarPago(uf.id, pago.uuid);}}
                                    >Verificar</Button>
                                </div> : null}
                        </div>
                    })}
                </div>
            </Collapse> 
        : null}
        {pagosHabilitados ?
            <div style={{textAlign: "center"}}>
                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    className={classes.Button}
                    startIcon={<PlaylistAddIcon />}
                    onClick={() => {setUf(uf); setDialogoOpen(true)}}
                >Registrar Pago</Button>
            </div>
        : null}
    </div>
}
