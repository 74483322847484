import React from "react";
import DrawerToggle from "components/navigation/sideDrawer/drawerToggle";
import Logo from "components/logo";
import NavigationItems from "components/navigation/navigationItems";
import classes from "./toolbar.module.css";

const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <DrawerToggle className={classes.MobileOnly} onClick={props.toogleSideDrawer}/>
        <Logo/>
        <nav className={classes.DesktopOnly}>
            <NavigationItems isAuth={props.isAuth}/>
        </nav>
    </header>
);

export default toolbar;