export default [
    {id: "TRANSFERENCIA", label: "Transferencia"},
    {id: "DEPOSITO", label: "Deposito"},
    {id: "EFECTIVO", label: "Efectivo"},
    {id: "PAGOFACIL", label: "Pago facil"},
    {id: "RAPIPAGO", label: "Rapipago"},
    {id: "MERCADOPAGO", label: "Mercado Pago"},
    {id: "TARJETACREDITO", label: "Tarjeta de Credito"},
    {id: "PAGOMISCUENTAS", label: "Pago Mis Cuentas"},
    {id: "OTRO", label: "Otro"},
];