import { useCallback } from "react";
import { Wrapper } from "./API";

export default () => {
    var [cargando, data, error,, setURLYEjecutar] = Wrapper(false, "GET");
    const ejecutar = useCallback((consorcioId) => {
        setURLYEjecutar(`expensas/edificio/${consorcioId}/pdfs`);
    }, [setURLYEjecutar]);
    return [cargando, data, error, ejecutar];
}
