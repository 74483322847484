import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from 'components/ui/AutoComplete/AutoComplete';
import Button from '@material-ui/core/Button';
import "./Dialogo.css";
import DatePicker from "components/ui/DatePicker/datePicker";
import Alert from "components/ui/alert/alert";
import TextField from "components/ui/textField/textField";
import formasDePago from "constants/formasDePago";
import PlataField from "components/ui/PlataField/PlataField";
import { doRegistrarPago } from "axios/cobranza/doRegistrarPago";
import Spinner from "components/ui/spinner"
import Form from "components/ui/Form/Form";

export default (props) => {
    const {dataUfs, open, setData, onClose} = props

    const [uf, setUf] = useState(props.uf);
    const [fechaPago, setFechaPago] = useState(new Date());
    const [nombre, setNombre] = useState("");
    const [formaPago, setFormaPago] = useState("");
    const [comprobanteNro, setComprobanteNro] = useState("");
    const [importe, setImporte] = useState();
    const [observacion, setObservacion] = useState("");
    const [validacion, setValidacion] = useState(null);
    const [cargandoRegistroDePago, dataRegistroDePago,, ejecutarRegistroDePago, clear] = doRegistrarPago();

    useEffect(() => {
        if (open) {
            if (props.uf)
                setUf(props.uf);
            else {
                setUf(null);
            }
        }
    }, [open, props.uf]);

    const handleClose = () => {
        onClose();
    };

    const handleAceptar = () => {
        setValidacion(null);
        if (!uf || uf === "") {
            setValidacion("No se seleccionó unidad funcional");
            return;
        }
        if (!formaPago || formaPago === ""){
            setValidacion("No se seleccionó una forma de pago");
            return;
        }
        if (!importe || importe <= 0) {
            setValidacion("Debe ingresar un valor (positivo) en el importe");
            return;
        }
        ejecutarRegistroDePago(uf.id, fechaPago, nombre, formaPago, comprobanteNro, importe, observacion, null);
        setFechaPago(new Date());
        setNombre("");
        setFormaPago("");
        setComprobanteNro("");
        setObservacion("");
        setImporte();
    }

    useEffect(() => {
        if (dataRegistroDePago) {
            dataUfs.find((uf) => uf.id === dataRegistroDePago.id).pagos = dataRegistroDePago.pagos;
            setData(dataUfs);
            clear();
            onClose();
        }
    }, [dataRegistroDePago, dataUfs, setData, clear, onClose])

    const onSetUf = (event, valor) => {
        if (!valor){
            setUf(null);
            return;
        }
        setUf(valor);
    };

    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" id="DialogoCobranza" >
        <DialogTitle className="form-dialog-title">Registrar Pago</DialogTitle>
        <Form>
            <DialogContent>
                <Alert severity="error" onClose={() => setValidacion(null)}>{validacion}</Alert>
                {dataUfs ? <Autocomplete
                    id="UF"
                    options={dataUfs ? dataUfs : []}
                    getOptionLabel={(option) => ( "UF: " + option.uf)}
                    renderOption={(option) => (
                        <React.Fragment>
                        UF: {option.uf} {option.piso ? "- Piso: " + option.piso : null} {option.dpto ? "- Dpto: " + option.dpto : null}
                        <br/>
                        {option.nombre}
                        </React.Fragment>
                    )}
                    onChange={onSetUf}
                    label="UF" 
                    getOptionSelected={(option, value) => (option.id === value.id)}
                    value={uf}
                /> : null }
                {uf && uf.piso ? <div style={{marginBottom: "0.5ch"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        Piso: <span style={{textAlign: "right"}}>{uf.piso}</span></div>
                </div> : null} 

                {uf && uf.dpto ? <div style={{marginBottom: "0.5ch"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        Dpto: <span style={{textAlign: "right"}}>{uf.dpto}</span></div>
                </div> : null} 

                {uf && uf.nombre ? <div style={{marginBottom: "1rem"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                    Propietario: <span style={{textAlign: "right"}}>{uf.nombre}</span></div>
                </div> : null} 
                
                <DatePicker id="fechaPago" 
                                label="Fecha de pago" 
                                value={fechaPago} 
                                setValue={setFechaPago} />
                <TextField label="Nombre" value={nombre}
                    onChange={(event) => setNombre(event.target.value)}
                    autoFocus/>
                <Autocomplete
                    id="formaDePago"
                    options={formasDePago}
                    getOptionLabel={(option) => (option.label)}
                    getOptionSelected={(option, value) => (option.label === value.label)}
                    onChange={(event, value) => {setFormaPago(value ? value.id : "")}}
                    label="Forma de pago" 
                />
                <TextField label="Comprobante Nro" 
                    value={comprobanteNro}
                    onChange={(event) => setComprobanteNro(event.target.value)}/>
                <PlataField label="Importe" name="Importe" id="importe"
                    value={importe}
                    onChange={(event) => setImporte(event.target.value)}/>
                <TextField label="Observaciones" 
                    value={observacion}
                    onChange={(event) => setObservacion(event.target.value)}/>
            </DialogContent>
            <DialogActions>
                {cargandoRegistroDePago ? <div className="Spinner"><Spinner centered/></div> : 
                        (<div className="BotonesDialogo">
                            <Button onClick={handleClose} color="secondary" variant="outlined">
                                Cancelar
                            </Button>
                            <Button type="submit" onClick={handleAceptar} color="primary" style={{fontWeight: 600}} variant="contained">
                                Agregar
                            </Button>
                        </div>)}
                
            </DialogActions>
        </Form>
    </Dialog>
}