import React from "react";
import Header from "./header/header";
import classes from "./liquidacion.module.css";
import SeccionSueldos from "./secciones/seccionSueldos/seccionSueldos";
import SeccionGastos from "./secciones/seccionGastos/seccionGastos";
import SeccionFinanciero from "./secciones/seccionFinanciero/seccionFinanciero";

export const Liquidacion = (props) => {
	return (
		<div>
			<Header administrador={props.administrador} edificio={props.edificio}/>
			<section className={classes.Section}>
				<SeccionSueldos {...props} />
				<SeccionGastos {...props} />
				<SeccionFinanciero {...props} />
			</section>
		</div>
	);	
}

export default Liquidacion;
