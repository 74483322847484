import React from "react";
import TablaArchivos from "components/tablaArchivos/tablaArchivos";
import {EXPENSAS_NO_ENCONTRADAS} from "constants/mensajes";


const onSuccessPDFs = (archivos) => {
	return (<div className="Archivos">
				<TablaArchivos archivos={archivos}/>
			</div>);
}

const onNullPdfs = (consorcioId) => {
	return consorcioId ? (<div>{EXPENSAS_NO_ENCONTRADAS}</div>) : null;
}

export {onSuccessPDFs, onNullPdfs};
