import React from "react";
import classes from "./total.module.css";
import m from "../../../utils/moneda";

const Total = (props) => {
	const descripcion = props.esSueldo ? (props.esSac ? "Total SAC:" : "Total Sueldo:") : "Total rubro:";
	if (props.expanded) {
		return (
			<div className={[classes.GastoRow, classes.TotalRubro].join(" ")}>
				<div className={classes.SubtotalDescripcion}>{descripcion}</div>
				<div className={classes.Importe}>{m(props.total)}</div>
			</div>
		);
	}
	else {
		return (
			<div className={classes.TotalRubroCollapsed}>
				<div className={classes.SubtotalRubro}>{descripcion} {m(props.total ? props.total : 0)}</div>
			</div>
		);
	}
}

export default Total;
