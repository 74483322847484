import React, { useState } from "react";
import classes from "./grupoGasto.module.css";
import Gastos from "../gastos/gastos";
import Subtotal from "../subtotal/subtotal";
import Total from "../total/total";
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade/Fade';
import Sueldo from "../sueldo/sueldo";

const GrupoGasto = (props) => {
	const [expanded, setExpanded] = useState(!props.expanded && !props.sueldo);
	const [collapsed, setCollapsed] = useState(props.expanded || !!props.sueldo);
	const clickCollapse = () => {
		setExpanded(!expanded);
	}
	const titulo = (<div className={classes.Titulo} onClick={() => clickCollapse()}>
						<div className={expanded ? classes.ArrowUp : classes.ArrowDown} />
						<div>{props.titulo}</div>
					</div>);
	//useEffect(() => {
	//	window.onscroll = () => {
	//		//console.log(window.pageYOffset);
	//	}
	//}, []);
	var child;
	if (props.sueldo) {
		child = <Sueldo sueldo={props.sueldo} headers={props.headers}/>;
	}
	else if (props.estadoFinanciero) {
		child = <div>Estado Financiero</div>;
	}
	else {
		child = <Gastos gastos={props.gastos} headers={props.headers}/>;
	}
	return 	(
				<div className={classes.Grupo}>
					<div onClick={()=>clickCollapse()}>{titulo}</div>
					<Collapse in={expanded} timeout={500} unmountOnExit collapsedHeight={"6ch"}
					onEnter={() => setCollapsed(false)} 
					onExited={() => setCollapsed(true)}>
						<div>
							{child}
							<Subtotal importes={props.subtotales} expanded={true} headers={props.headers}/>
							<Total total={props.total} expanded esSueldo={props.sueldo} esSac={props.sueldo && props.sueldo.sac}/>
						</div>
					</Collapse>
					<Fade in={collapsed} className={collapsed ? classes.Collapsed : classes.Hidden} timeout={500} unmountOnExit>
						<div>
							<Total total={props.total} esSueldo={props.sueldo} expanded={expanded} esSac={props.sueldo && props.sueldo.sac}/>	
							<Subtotal importes={props.subtotales} expanded={expanded} headers={props.headers}/>
						</div>
					</Fade>
				</div>
	)
}

GrupoGasto.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default GrupoGasto;
