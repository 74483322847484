import React, { useEffect, useState } from "react";
import firebase from "./"
import Spinner from "components/ui/spinner";

export const Auth = React.createContext();

export const AuthContext = ({ children }) => {
    const [usuario, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);

    useEffect(() => {
        const unlisten = firebase.auth.onAuthStateChanged(
           user => {
                setUsuario(user);
                setShowChild(true);
           }
        );
        return () => {
            unlisten();
        }
    }, []);

    if (!showChild)
        return (<Spinner centered />);
    else
        return (
            <Auth.Provider value={{usuario}}>
                {children}
            </Auth.Provider>
        );
};