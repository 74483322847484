import React, { useContext } from "react";
import { Redirect } from "react-router-dom"
import { withFirebase } from "../../firebase";
import { Auth } from "components/firebase/authContext";
import FirebaseUIAuth from "react-firebaseui-localized";
import classes from "./index.module.css";

const IngresoPage = (props) => {
	const {usuario} = useContext(Auth);
	let uiConfig = props.firebase.uiConfig;
	if(props.location.state && props.location.state.from && props.location.state.from.pathname) {
		uiConfig.signInSuccessUrl = props.location.state.from.pathname;
	}
	return (
		usuario != null ? <Redirect to={uiConfig.signInSuccessUrl} /> :
			<React.Fragment>
    			<h1 className={classes.Titulo}>
					<span className={classes.Liquid}>liquid</span>
					<span className={classes.PuntoAmarillo}>.</span>
					<span className={classes.AR}>ar</span>
					<br/>Expensas Online
					<br/>Servicios Informáticos Piscis
				</h1>
				<div className={classes.Grid}>
					<h3 className={classes.TextoBienvenida}>
						Bienvenidos a nuestro nuevo portal.
						Nos estamos actualizando, dándoles tanto a los propietarios 
						como a los administradores una herramienta cómoda y amigable 
						para tener toda la información al alcance de la mano.
						<p/>Seguiremos mejorando día a día.
						<br/>Gracias por confiar en nosotros.
					</h3>
					<FirebaseUIAuth 
						lang="es_419"
						config={props.firebase.uiConfig}
						auth={props.firebase.auth}
						firebase={props.firebase.firebaseApp}/>
				</div>
			</React.Fragment>
	);
}

export default withFirebase(IngresoPage);
