import React, { useEffect, useState } from "react";
import classes from "./TablaComunicacionesAdministrador.module.css";
import { Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Button } from "@material-ui/core";
import ComunicacionesForm from "components/ComunicacionesForm/ComunicacionesForm";
import RowTablaComunicaciones from "./RowTablaComunicacionesAdministrador";
import useGetComunicaciones from "axios/useGetComunicaciones";
import queryMiddleState from "components/queryMiddleState/queryMiddleState";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from "components/ui/alert/alert";
import Dialog from "components/ui/Dialog/Dialog";
import useEliminarComunicaciones from "axios/useEliminarComunicaciones";

export default (props) => {
    const getComunicaciones = useGetComunicaciones();
    const {ejecutar} = getComunicaciones;
    const eliminarComunicaciones = useEliminarComunicaciones();
	const [comunicaciones, setComunicaciones] = useState([]);
    const [comunicacionParaModificar, setComunicacionParaModificar] = useState(null);
	const [comunicacionABorrar, setComunicacionABorrar] = useState(null);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(null);

    useEffect(() => {
		if (!props.consorcioId || props.consorcioId === "" || getComunicaciones.data)
			return;
            ejecutar(props.consorcioId);
	}, [ejecutar, getComunicaciones.data, props.consorcioId])


	useEffect(() => {
            setComunicaciones(getComunicaciones.data);
	}, [setComunicaciones, getComunicaciones.data])

    const handleClose = (data) => {
        if (data) {
            if (data.am === "m")
                updateComunicacion(data.data)
            else if (data.am === "a")
                crearComunicacion(data.data)
        }
        setOpen(false);
        setComunicacionParaModificar(null);
    }

    const crearComunicacion = (data) => {
        setComunicaciones(prevComunicaciones => {
            prevComunicaciones.push(data)
            return prevComunicaciones;
        });
        setAlert({"severity": "success", "mensaje": "¡Ya creamos la comunicación!"});
    }

	const updateComunicacion = (data) => {
        setAlert(null);
		setComunicaciones(comunicaciones => {
			const index = comunicaciones.findIndex(function(i){
				return i.id === data.id;
			});
			comunicaciones[index] = data;
			return comunicaciones;
		});
        setAlert({"severity": "success", "mensaje": "¡Ya actualizamos la comunicación!"});
	}

    const confirmarBorrado = async (data) => {
        setAlert(null);
        setComunicacionABorrar(null);
        if (data){
            const response = await eliminarComunicaciones.ejecutar(props.consorcioId, data);
            if (response.data){
                setComunicaciones(comunicaciones => {
                    comunicaciones.splice(comunicaciones.findIndex(function(i){
                        return i.id === response.data;
                    }), 1);
                    return comunicaciones;
                });
                setAlert({"severity": "success", "mensaje": "¡Ya eliminamos la comunicación!"});
            }
            else if (response.error) {
                console.log(response.error)
                setAlert({"severity": "error", "mensaje": "¡Hubo un error al eliminar la comunicación!" + 
                    (response.error.data.message ? " " + response.error.data.message : "")});
            }
            
        }
    }

	return queryMiddleState(getComunicaciones.error, getComunicaciones.cargando, comunicaciones, 
		() => null, 
		() => {return onSuccessComunicaciones(props.consorcioId, comunicaciones, 
                                              comunicacionParaModificar, setComunicacionParaModificar, 
                                              handleClose,
                                              confirmarBorrado, eliminarComunicaciones.cargando,
                                              open, setOpen, 
                                              alert, setAlert,
                                              comunicacionABorrar, setComunicacionABorrar)});
}
const onSuccessComunicaciones = (consorcioId, comunicaciones, 
                                comunicacionParaModificar, setComunicacionParaModificar, 
                                handleClose, 
                                confirmarBorrado, eliminarCargando, 
                                open, setOpen, 
                                alert, setAlert, 
                                comunicacionABorrar, setComunicacionABorrar) => {
    if(!consorcioId)
        return null
    return <React.Fragment>
                {alert ?    <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                                {alert.mensaje}
                            </Alert> : null}
                <Dialog open={comunicacionABorrar !== null} 
                        mensaje="¿Confirmás que querés eliminar la comunicación seleccionada?"
                        handleConfirmar={() => confirmarBorrado(comunicacionABorrar)} 
                        handleCancelar={() => setComunicacionABorrar(null)}
                        iconoConfirmar={<DeleteIcon/>}
                        iconoCancelar={<ClearIcon/>}/>
                <ComunicacionesForm open={comunicacionParaModificar !== null || open}
                                    handleClose={(success) => handleClose(success)} 
                                    comunicacion={comunicacionParaModificar}
                                    consorcioId={consorcioId}/>
                <TableContainer className={classes.Table}>
                    <Table style={{"overflow":"hidden"}}>
                        <TableHead>
                            <TableRow className={classes.Header}>
                                <TableCell className={classes.TituloHead}>Título</TableCell>
                                <TableCell>Mensaje</TableCell>
                                <TableCell className={classes.DateHead}>Mostrar desde</TableCell>
                                <TableCell className={classes.DateHead}>Mostrar hasta</TableCell>
                                <TableCell className={classes.BotonesHead}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {comunicaciones.map((comunicacion, i) => {
                            return <RowTablaComunicaciones key={i} 
                                        comunicacion={comunicacion} 
                                        mostarYCargarDialogo={(comunicacion) => setComunicacionParaModificar(comunicacion)}
                                        onBorrado={(id) => setComunicacionABorrar(id)}
                                        eliminando={eliminarCargando}/>
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.Button}>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            style={{"width": "max-content", "alignSelf": "center"}}
                            onClick={() => setOpen(true)}>Crear comunicación</Button>
                </div>
            </React.Fragment>
}

