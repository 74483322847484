import React from "react";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { format } from "date-fns";
import { TableCell, TableRow, IconButton, AccordionSummary, Accordion, Tooltip } from "@material-ui/core";
import classes from "./TablaComunicacionesAdministrador.module.css";
import Spinner from "components/ui/spinner";

export default (props) => {
    return (<TableRow className={classes.Row}>
        <TableCell className={classes.CellLeft} component="th" scope="row">
            {props.comunicacion.titulo}
        </TableCell>
        <TableCell className={classes.Cell}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    {props.comunicacion.mensaje}
                </AccordionSummary>
            </Accordion>
            
        </TableCell>
        <TableCell className={classes.Cell}>
            {props.comunicacion.mostrarHasta ?
                format(new Date(props.comunicacion.mostrarDesde),"dd/MM/yyyy HH:mm") :
                "-"
            }
            </TableCell>
        <TableCell className={classes.Cell}>
            {props.comunicacion.mostrarHasta ?
                format(new Date(props.comunicacion.mostrarHasta),"dd/MM/yyyy HH:mm") :
                "-"
            }
        </TableCell>
        <TableCell className={classes.Cell}>
            {props.eliminando ?  <Spinner centered/> : 
                <div className={classes.Botones}>
                    <Tooltip title="Modificar" aria-label="modificar">
                        <IconButton aria-label="modificar" size="small" 
                                    onClick={() => {props.mostarYCargarDialogo(props.comunicacion)}}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar" aria-label="eliminar">
                        <IconButton aria-label="eliminar" size="small"
                                    onClick={() => {
                                        props.onBorrado(props.comunicacion.id)
                                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            }
        </TableCell>
    </TableRow>) 
}
