import format from 'date-fns/format'

export const fechaHandler = (valor) => {
    var date = split(valor)
    var mes = date[1];
    var dia = date[2];
    var formattedDate = dia + '/' + mes + '/' + date[0];
	return formattedDate;
}

const split = (fecha) => {
    if (fecha.includes("-"))
        return fecha.split("-");
    else
        return fecha.split("/");
}

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
"Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const nombreMes = (mes) => {
    return meses[parseInt(mes)-1];
}
export const nombreMesYAnio = (fecha) => {
    var fechaSplitted = split(fecha);
    return `${nombreMes(fechaSplitted[1])} ${fechaSplitted[0]}`;
}

export const formatDateISO = (fecha) => {
    return format(fecha, "yyyy-MM-dd'T'HH:mm:ss")
}

export default fechaHandler;
