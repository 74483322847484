import { useCallback } from 'react';
import { Wrapperv2 } from './APIv2';

export default () => {
    var wrapper = Wrapperv2(false, "GET");
    const {setURLYEjecutar} = wrapper;
    const ejecutar = useCallback(async (edificioId) => {
        return await setURLYEjecutar(`expensas/edificio/${edificioId}`);
    }, [setURLYEjecutar]);
    return {"cargando": wrapper.cargando, "data": wrapper.data, "error": wrapper.error, "ejecutar": ejecutar, "clear": wrapper.clear};
}