import React from "react";
import Gasto from "../gasto/gasto";
import GastoHeader from "../gastoHeader/gastoHeader";
import classes from "./gastos.module.css"

export default (props) => {
    return  <div className={classes.Gastos}>
                <GastoHeader headers={props.headers} />
                {props.gastos.map((gasto, i) => {
                return (<Gasto  descripcion={gasto.descripcion} 
                                importes={gasto.importes}
                                cantidadColumnas={props.headers.length} 
                                key={i}/>);
                })}
            </div>
}
