import { useCallback } from 'react';
import { Wrapper } from './API';

export default () => {
    var [cargando, data, error,, setURLYEjecutar] = Wrapper(false, "GET");
    const ejecutar = useCallback((esAdministrador) => {
        const url = "consorcios" + (esAdministrador ? "/administrador" : "")
        setURLYEjecutar(url);
    }, [setURLYEjecutar])
    return {cargando, data, error, ejecutar};
}