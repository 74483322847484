import React, { useEffect, useState } from "react";
import ConsorciosDropDown from "components/pages/consorcios/expensas/componentes/ConsorciosDropDown";
import classes from "./Cobranza.module.css";
import LiquidacionesDropDown from "../consorcios/expensas/componentes/LiquidacionesDropDown";
import getProrrateo from "axios/getProrrateo";
import queryMiddleState from "components/queryMiddleState/queryMiddleState";
import { Fab } from "@material-ui/core";
import { COBRANZA_ID_EDI, COBRANZA_ID_EDI_EXP } from "constants/routes";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Dialogo from "./Dialogo";
import TextField from "components/ui/textField/textField";
import { doCambiarEstadoPago } from "axios/cobranza/doCambiarEstadoPago";
import DialogoConfirmacion from "./DialogoConfirmacion";
import TablaMobile from "./TablaMobile";
import TablaDesktop from "./TablaDesktop";

export default (props) => {
	const [consorcioId, setConsorcioId] = useState(props.match.params.edificioId ? props.match.params.edificioId : "");
	const [liquidacionId, setLiquidacionId] = useState(props.match.params.expensaId ? props.match.params.expensaId : "");
	const [filtro, setFiltro] = useState("");
	const [dialogoOpen, setDialogoOpen] = useState(false);
	const [params, setParams] = useState(null);
	const [uf, setUf] = useState("");
	const prorrateo = getProrrateo();
    const [cargandoCambiarEstadoPago, respuestaCambioEstado,, ejecutarCambiarEstadoPago] = doCambiarEstadoPago();
    const [data, setData] = useState([]);

	const {ejecutar} = prorrateo;
    
    useEffect(() => {
        if (props.match.params.expensaId) {
            ejecutar(props.match.params.expensaId);
        }
    }, [ejecutar, props.match.params])

    const seleccionarConsorcio = (consorcioId) => {
        window.history.replaceState(null, "", COBRANZA_ID_EDI.replace(":edificioId", consorcioId));
        setConsorcioId(consorcioId);
        setData([])
        setLiquidacionId("");
        prorrateo.clear();
        setFiltro("");
    }

    const cargarProrrateo = (liquidacionId) => {
        window.history.replaceState(null, "", COBRANZA_ID_EDI_EXP
                                .replace(":edificioId", consorcioId)
                                .replace(":expensaId", liquidacionId));
        setLiquidacionId(liquidacionId);
        ejecutar(liquidacionId);
        setFiltro("");
    }

    const toggleDialog = (uf) => {
        setUf(uf);
        setDialogoOpen(!dialogoOpen);
    }

    const verificarPago = (uf, pagoId) => {
        setParams({uf: uf, pagoId: pagoId, estado: "aprobar"});
    }

    const rechazarPago = (uf, pagoId) => {
        setParams({uf: uf, pagoId: pagoId, estado: "rechazar"});
    }

    const modificarEstadoPago = (observacion) => {
        ejecutarCambiarEstadoPago(params.uf, params.pagoId, params.estado, observacion);
        setParams(null);
    }

    useEffect(() => {
        //Al cambiar el estado de un pago
        if (respuestaCambioEstado){
            data.find((uf) => uf.id === respuestaCambioEstado.id).pagos = respuestaCambioEstado.pagos;
            setData(data);
        }
    }, [respuestaCambioEstado, data])

    useEffect(() => {
        setData(prorrateo.data ? prorrateo.data.ufs : []);
    }, [prorrateo.data])

    const filtrar = () => {
        return data.filter(e =>   e.nombre && (e.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
                                            e.uf.includes(filtro)));
    }

    const OnSuccessProrrateo = (data) => {
        if (!data || data.length === 0) {
            return;
        }
        data = calcularTotales(data)
        return <React.Fragment>
            { data.pagosHabilitados ?
                <Fab className={classes.Fab} onClick={() => toggleDialog("")}>
                    <PlaylistAddIcon />
                </Fab>: null
            }
            <Dialogo open={dialogoOpen} onClose={() => setDialogoOpen(false)} dataUfs={data} setData={(data) => setData(data)} uf={uf}/>
            <DialogoConfirmacion params={params} setParams={setParams} modificarEstadoPago={modificarEstadoPago}/>
            <div className={classes.TableDiv}>
                {!window.mobileCheck() ?
                    <TablaDesktop   data={data} 
                                    setUf={setUf} 
                                    setDialogoOpen={setDialogoOpen} 
                                    verificarPago={verificarPago} 
                                    rechazarPago={rechazarPago} 
                                    cargandoCambiarEstadoPago={cargandoCambiarEstadoPago}
                                    pagosHabilitados={prorrateo.data.pagosHabilitados}/>
                    :
                    <TablaMobile    data={data} 
                                    setUf={setUf} 
                                    setDialogoOpen={setDialogoOpen}
                                    verificarPago={verificarPago}
                                    rechazarPago={rechazarPago}
                                    cargandoCambiarEstadoPago={cargandoCambiarEstadoPago}
                                    pagosHabilitados={prorrateo.data.pagosHabilitados}/>
                }
            </div>
        </React.Fragment>;
    }
    return (
        <React.Fragment>
            <div className={classes.Filtros}>
                <div className={classes.ConsorciosDropDown}>
                    <ConsorciosDropDown consorcioId={consorcioId} 
                                        setConsorcioId={(consorcioId) => seleccionarConsorcio(consorcioId)} 
                                        leyenda={"¿Para qué edificio?"}
                                        esAdministrador
                                        />
                </div>
                { consorcioId !== "" ?
                <div className={classes.LiquidacionDropDown}>
                    <LiquidacionesDropDown  consorcioId={consorcioId} 
                                            liquidacionId={liquidacionId}
                                            setLiquidacionId={(liquidacionId) => cargarProrrateo(liquidacionId)}
                                            seleccionarPrimerValor 
                                        />
                </div> : null
                }
            </div>
            <br/>
            {data && data.length > 0 ? 
                <div className={classes.Busqueda}>
                    <TextField label="Buscar por UF o Co-propietario" value={filtro} onChange={e => setFiltro(e.target.value)}/>
                </div> : null}
            
            {filtro ? OnSuccessProrrateo(filtrar()) :
            queryMiddleState(prorrateo.error, prorrateo.cargando, prorrateo.data, () => {}, 
                () => {return OnSuccessProrrateo(data)})}

        </React.Fragment>
	)
}

const calcularTotales = (data) => {
    data.forEach(element => {
        if (!element.pagos) {
            element.totalPagado = 0;
            return;
        }
        var totalPagado = 0;
        element.pagos.forEach((pago) => {
            if (pago.estadoPago === "RECHAZADO")
                return;
            totalPagado += pago.importeAbonado
            if (pago.estadoPago === "PENDIENTE_VERIFICACION")
                element.pagosAVerificar = true;
        })
        element.totalPagado = totalPagado;
    });
    return data;
}
