import React, { useEffect, useState } from "react";
import classes from "./TablaComunicacionesUsuario.module.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useGetComunicaciones from "axios/useGetComunicaciones";
import queryMiddleState from "components/queryMiddleState/queryMiddleState";

export default (props) => {
    const getComunicaciones = useGetComunicaciones();
    const [consorcioId, setConsorcioId] = useState("");
    const {ejecutar} = getComunicaciones;
    
	useEffect(() => {
		if (!props.consorcioId || props.consorcioId === "" || consorcioId === props.consorcioId)
			return;
        setConsorcioId(props.consorcioId);
        ejecutar(props.consorcioId);
	}, [ejecutar, getComunicaciones.data, props.consorcioId, consorcioId, setConsorcioId])
	return queryMiddleState(getComunicaciones.error, getComunicaciones.cargando, getComunicaciones.data, 
		() => null, 
		() => {return onSuccessComunicaciones(getComunicaciones.data, props.consorcioId)});
};

const onSuccessComunicaciones = (comunicaciones) => {
    if (comunicaciones.length === 0){
        return null;
    }
    else {
        return (
            <div className={classes.Comunicaciones}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.AccordionSummary}>
                        <div>Comunicaciones</div>
                        <div>{comunicaciones.length}</div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.ComunicacionesBody}>
                    {comunicaciones.map((comunicacion, i) => {
                        return (<Accordion key={"panel"+i} defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.TituloComunicacion}>{comunicacion.titulo}</div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.ComunicacionMensaje}>
                                        <div>{comunicacion.mensaje}</div>
                                    </AccordionDetails>
                                </Accordion>)
                    })}
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}
