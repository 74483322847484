import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Link } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import f, { nombreMesYAnio } from "utils/fecha";
import classes from "./tablaArchivos.module.css";
import "./tablaArchivos.css"
import Tooltip from "components/ui/Tooltip/Tooltip";

export default (props) => {
    if (props.archivos.length === 0)
        return null;
    else
        return (
            <TableContainer className={classes.Table}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow className={classes.Header}>
                            <TableCell>Periodo</TableCell>
                            <TableCell>Fecha de vencimiento</TableCell>
                            <TableCell className={classes.HeaderDownload}>Descargar</TableCell>
                            <TableCell className={classes.HeaderDownloadEmpty}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.archivos.map((archivo, i) => {
                        var periodo = `${nombreMesYAnio(archivo.periodo)}`;
                        return (
                            <TableRow key={archivo.descripcion + i} className={classes.Row}>
                                <TableCell className={classes.Cell} component="th" scope="row">
                                    <Tooltip title="Ver liquidación" placement="right" arrow>
                                        <Link to={`/expensas/${archivo.expensaId}`} className={classes.LinkHeader} style={{color: "var(--blue)", textDecoration: "auto"}}>
                                            {periodo}
                                        </Link>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={classes.Cell}>{f(archivo.vencimiento)}</TableCell>
                                <TableCell className={classes.CellDownload}>
                                    <a target={"_blank"} href={archivo.url} rel="noopener noreferrer">{<GetAppIcon className={classes.IconoDescarga}/>}</a>
                                </TableCell>
                            </TableRow>
                        )})
                    }
                    </TableBody>
                </Table>
            </TableContainer>);
}
