import { Wrapper } from './API';
export const doEmpadronamientoRequest = () => {
    const [cargando, data, error,, setURLYEjecutar] = Wrapper(false, "POST");
    const ejecutar = (consorcioId, uf, then) => {
        setURLYEjecutar(`usuario/edificio/${consorcioId}/uf/${uf}`, then);
    }
    return [cargando, data, error, ejecutar];
}

export default (consorcioId, uf, then) => {
    const url = uf ? `usuario/edificio/${consorcioId}/uf/${uf}` : `usuario/edificio/${consorcioId}`
    return Wrapper(true, "POST", url, then);
}
