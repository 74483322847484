import React, { useCallback, useEffect } from "react";
//import QueryMiddleState from "components/queryMiddleState/queryMiddleState";
import QueryMiddleState2 from "components/queryMiddleState/queryMiddleState2";
import Mensaje from "components/ui/mensaje/mensaje";
import Select from "components/ui/Select/Select";
import getLiquidaciones from "axios/getLiquidaciones";
import Spinner from "components/ui/spinner";

export default React.memo((props) => {
	const liquidaciones = getLiquidaciones();
    const {setLiquidacionId} = props;

    const setLiquidacion = useCallback((liquidacionId) => {
		setLiquidacionId(liquidacionId);
    }, [setLiquidacionId]);

	const {ejecutar} = liquidaciones;
	useEffect(() => {
		ejecutar(props.consorcioId);
    }, [ejecutar, props.consorcioId]); 


	const OnSuccessLiquidacion = (data) => {
		if (data.length === 0) {
			return  <Mensaje>
						<p>¡Uy! No hay ninguna liquidación para este consorcio todavía.</p>
					</Mensaje>
		}
		if (data[0].edificioId !== props.consorcioId)
			return <Spinner centered />;

		return (<React.Fragment>
				<Select
					value={props.liquidacionId} 
					setId={setLiquidacion} 
					data={data} 
					seleccionarPrimerValor={props.seleccionarPrimerValor}
					propiedadComoId="id"
					id="selectLiquidacion"
					getValorMostrar={(valor) => valor.periodo.substring(0,7)}/>
		</React.Fragment>)
	}
	return <QueryMiddleState2 
				{...liquidaciones} 
				onNull={() => {return <React.Fragment></React.Fragment>}} 
				onSuccess={() => {return OnSuccessLiquidacion(liquidaciones.data)}}/>
});

