import { Wrapper } from '../API';
export const doRegistrarPago = () => {
    const [cargando, data, error,, setURLYEjecutar, clear] = Wrapper(false, "POST");
    const ejecutar = (ufId, fechaPago, nombre, formaDePago, comprobante, importeAbonado, observacion, then) => {
        setURLYEjecutar(`cobranzas/pago`, then, 
        {   
            ufId: ufId, 
            fechaPago: fechaPago, 
            nombre: nombre, 
            formaDePago: formaDePago, 
            comprobante: comprobante, 
            importeAbonado: importeAbonado,
            observacion: observacion
        });
    }
    return [cargando, data, error, ejecutar, clear];
}
