import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import DateTimePicker from "components/ui/dateTimePicker/dateTimePicker";
import classes from "./ComunicacionesForm.module.css";
import "./ComunicacionesForm.css";
import CheckIcon from '@material-ui/icons/Check';
import TextField from "components/ui/textField/textField";
import addDays from 'date-fns/addDays'
import { formatDateISO } from 'utils/fecha'
import useComunicacion from "axios/useComunicacion";
import Spinner from "components/ui/spinner";
import Alert from "components/ui/alert/alert";
import { parseISO } from "date-fns";

export default (props) => {
    const [titulo, setTitulo] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [fechaDesde, setFechaDesde] = useState(null);
    const [fechaHasta, setFechaHasta] = useState(null);
    const [radioSelected, setRadioSelected] = useState("true");
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState(null);
    var {data, error, cargando, ejecutar, clear} = useComunicacion();
    const {handleClose, comunicacion, open} = props;
    
    const reiniciar = useCallback(() => {
        setTitulo("");
        setMensaje("");
        clear();
        setLoading(false);
        setValidationError(null);
    }, [clear]);

    //limpio los datos de axios cuando abro la ventana por si es la segunda vez
    useEffect(() => {
        if (open){
            reiniciar();
        }
    }, [open, reiniciar])

    //carga de datos
    useEffect(() => {
        if (comunicacion) {
            setTitulo(comunicacion.titulo);
            setMensaje(comunicacion.mensaje);
            if (comunicacion.mostrarDesde !== null) {
                setRadioSelected("true");
                setFechaDesde(parseISO(comunicacion.mostrarDesde));
                setFechaHasta(parseISO(comunicacion.mostrarHasta));
            }
            else {
                setRadioSelected("false");
            }
        }
        else {
            const date = new Date();
            setFechaDesde(date);
            setFechaHasta(addDays(date, 1));
        }
    }, [comunicacion])

    //alta o modif correcta
    useEffect(() => {
        if (data !== null) {
            const response = {"am": comunicacion ? "m" : "a", "data": data};
            reiniciar();
            handleClose(response);
        }
    }, [data, handleClose, comunicacion, reiniciar])

    const handleSubmit = () => {
        setLoading(true);
        setValidationError(null);
        if (titulo === null || titulo.trim() === "") {
            setValidationError("El título no puede estar vacio");
            setLoading(false);
            return;
        }
        if (mensaje === null || mensaje.trim() === "") {
            setValidationError("El mensaje no puede estar vacio");
            setLoading(false);
            return;
        }
        if (radioSelected === "true") {
            ejecutar(props.consorcioId, formatDateISO(fechaDesde), formatDateISO(fechaHasta), 
                    titulo, mensaje, comunicacion ? comunicacion.id : null);
        }
        else {
            ejecutar(props.consorcioId, null, null, titulo, mensaje, 
                    comunicacion ? comunicacion.id : null);
        }
    }

    return (<Dialog open={open} onClose={() => handleClose(null)} aria-labelledby="form-dialog-title" id="DialogoAMComunicacion">
                <DialogContent>
                    <div className={classes.Main}>
                        <Alert variant="outlined" severity="warning" onClose={() => setValidationError(null)}>{validationError}</Alert>
                        <Alert variant="outlined" severity="error">{error ? JSON.stringify(error.data) : null}</Alert>
                        <FormControl id="FormCrearComunicacion">
                            <TextField  id="titulo" 
                                        label="Titulo" 
                                        onChange={(event) => setTitulo(event.target.value)}
                                        value={titulo}
                                        autoFocus/>
                            <TextField  id="mensaje" 
                                        label="Mensaje" 
                                        onChange={(event) => setMensaje(event.target.value)}
                                        value={mensaje}
                                        multiline 
                                        />
                            <RadioGroup name="programacion" value={radioSelected} onChange={(event) => setRadioSelected(event.target.value)}>
                                <FormControlLabel value={"false"} control={<Radio />} label="Mostrar por tiempo indefinido" />
                                <FormControlLabel value={"true"} control={<Radio />} label="Mostrar solo por un tiempo" />
                            </RadioGroup>
                            <DateTimePicker id="desde" 
                                            label="Desde" 
                                            value={fechaDesde} 
                                            setValue={setFechaDesde} 
                                            minDate={comunicacion ? comunicacion.fechaDesde : new Date()}
                                            disabled={radioSelected === "false"}
                                            hidden={radioSelected === "false"}/>
                            <DateTimePicker id="hasta" 
                                            label="Hasta" 
                                            value={fechaHasta} 
                                            setValue={setFechaHasta} 
                                            minDate={fechaDesde} 
                                            disabled={radioSelected === "false"}
                                            hidden={radioSelected === "false"}/>
                            {cargando || loading ? 
                                <Spinner centered/> : 
                                <Button variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<CheckIcon />}
                                        style={{"width": "max-content", "alignSelf": "center"}}
                                        onClick={handleSubmit}>{comunicacion ? "Modificar" : "Crear"} comunicación</Button>
                            }
                        </FormControl>
                    </div>
                </DialogContent>
            </Dialog>)
};
