import React from "react";
import SectionHeader from "components/liquidacion/secciones/sectionHeader/sectionHeader";
import GrupoGasto from "components/liquidacion/grupoGasto/grupoGasto";
import {nombreMesYAnio} from "utils/fecha";

export default (props) => {
    return (props.grupoSueldos && props.grupoSueldos.sueldos && props.grupoSueldos.sueldos.length > 0 ? 
    (<React.Fragment>
        <SectionHeader header={"REMUNERACIONES AL PERSONAL Y CARGAS SOCIALES\n" + nombreMesYAnio(props.periodo)}/>
        
        {props.grupoSueldos.sueldos.map((sueldo, i) => {
            return <GrupoGasto 
                headers={props.headers}
                sueldo={sueldo}
                subtotales={sueldo.subtotales}
                total={sueldo.total}
                titulo={`${sueldo.sac ? "SAC" : "Sueldo"}: ${sueldo.nombreYApellido} - CUIL: ${sueldo.cuil}`}
                key={i}/>
        })}
        <GrupoGasto headers={props.headers} {...props.grupoSueldos}/>
    </React.Fragment>) : null);
}
