import React, { useContext, useState } from "react";
import { Auth } from "components/firebase/authContext";

const Token = () => {
    const [token, setToken] = useState("");
    const {usuario} = useContext(Auth);
    usuario.getIdToken(false)
        .then(function(token) {
            setToken(token);
        });
    return <div style={{"width": "70%", "wordBreak": "break-all", "textAlign": "center"}}>{token}</div>;
};

export default Token;
