import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import FirebaseContext from "./components/firebase/context";
import firebase from "./components/firebase/";
import { AuthContext } from 'components/firebase/authContext';

ReactDOM.render(
	<FirebaseContext.Provider value={firebase}>
		<AuthContext>
				<BrowserRouter>
					<App />
				</BrowserRouter>
		</AuthContext>
	</FirebaseContext.Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();
