import React from "react";
import Spinner from "components/ui/spinner"
import { FIREBASE_NO_ENCONTRADO, SERVER_ERROR } from "constants/mensajes";
import Mensaje from "components/ui/mensaje/mensaje"

export default (error, cargando, data, onNull, onSuccess, onError) => {
	if (error !== null) {
        if (error === undefined)
            return <Mensaje>{SERVER_ERROR}</Mensaje>;
        if (error.status === 404) {
            return onNull();
        }
        if (onError)
            return onError;
        return (error.status);
    }
	if (cargando)
        return (<Spinner centered />);

	if (data === null) {
        if (onNull)
            return onNull();
        else
            return (<Mensaje>{FIREBASE_NO_ENCONTRADO}</Mensaje>);
    }
    
    if (onSuccess)
        return onSuccess();
        
    return null;
};
