import React, { useState } from "react";
import { Badge, Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import m from "utils/moneda";
import fechaHandler from "utils/fecha";
import classes from "./Cobranza.module.css";
import Tooltip from "components/ui/Tooltip/Tooltip";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import formasDePago from "constants/formasDePago";
import estadosPago from "constants/estadosPago";
import Spinner from "components/ui/spinner";
import ChatIcon from '@material-ui/icons/Chat';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';

export default (props) => {
    const { ufData, colSpan, pagosHabilitados } = props;
    const [open, setOpen] = useState(false);
    return <React.Fragment>
        <TableRow key={ufData.id} className={props.i % 2 === 0 ? classes.Row : classes.Row2} style={ufData.pagos ? {borderBottom: "none"} : {}}>
            <TableCell className={classes.Cell}>
                {ufData.uf}
            </TableCell>
            {ufData.dpto ? <React.Fragment>
                <TableCell className={classes.Cell}>
                    {ufData.piso}
                </TableCell>
                <TableCell className={classes.Cell}>
                    {ufData.dpto}
                </TableCell>
            </React.Fragment> : 
                <TableCell className={classes.Cell} colSpan="2">
                    {ufData.piso}
                </TableCell>}
            <TableCell className={classes.Cell} style={{textAlign: "left !important"}}>
                {ufData.nombre}
            </TableCell>
            {
                ufData.totales.map((total, key) => 
                    <TableCell key={key} className={classes.Cell} style={{textAlign: "right !important"}}>
                        <span className={total < 0 ? classes.SaldoAFavor : null}>{m(total)}</span>
                    </TableCell>)
            }
            {pagosHabilitados ? 
                <React.Fragment>
                    <TableCell className={classes.Cell}>
                    {`${m(ufData.totalPagado)}`}
                    </TableCell>
                    <TableCell className={`${classes.Cell} ${classes.CellIconos}`}>
                        <Tooltip title="Registrar pago" arrow>
                            <IconButton aria-label="Registrar Pago" size="small" onClick={() => {props.setUf(ufData); props.setDialogoOpen(true)}}>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={`${classes.Cell} ${classes.CellIconos}`}>
                    {ufData.pagos ? 
                        <Badge variant="dot" color="error" invisible={!ufData.pagos.some(pago => pago.estadoPago === "PENDIENTE_VERIFICACION")}>
                            <Tooltip title={open ? "Ocultar pagos" : "Mostrar pagos"} arrow>
                                <IconButton aria-label="expandir" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </Tooltip>
                        </Badge> : null}
                    </TableCell>
                </React.Fragment>
             : null}
        </TableRow>
        {ufData.pagos ? 
        <TableRow className={classes.PagosRow}>
            <TableCell style={{ padding: 0, border: "none"}} colSpan={colSpan}>
                <Collapse in={open} timeout="auto" >
                    <Box margin={1}>
                        <TableContainer className={classes.TablePagos}>
                            <Table size="small">
                                <TableHead>
                                <TableRow className={classes.Header}>
                                    <TableCell>Fecha Aviso</TableCell>
                                    <TableCell>Fecha Pago</TableCell>
                                    <TableCell>Forma de Pago</TableCell>
                                    <TableCell>Comprobante</TableCell>
                                    <TableCell>Importe Abonado</TableCell>
                                    <TableCell>Estado del pago</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {ufData.pagos ? ufData.pagos.map((pago, key) => {
                                    var estadoPago = estadosPago.filter(e => e.id === pago.estadoPago)[0];
                                    var estadoPagoStyle = "";
                                    if (estadoPago.id === "RECHAZADO")
                                        estadoPagoStyle = classes.EstadoRechazado;
                                    else if (estadoPago.id === "PENDIENTE_VERIFICACION")
                                        estadoPagoStyle = classes.EstadoPendiente;
                                    else if (estadoPago.id === "VERIFICADO")
                                        estadoPagoStyle = classes.EstadoVerificado;
                                    
                                    return (
                                    <TableRow key={key} className={key % 2 === 0 ? classes.Row : classes.Row2}>
                                        <TableCell className={classes.Cell}>{fechaHandler(pago.fechaAviso.substring(0, 10))}</TableCell>
                                        <TableCell className={classes.Cell}>{fechaHandler(pago.fechaPago.substring(0, 10))}</TableCell>
                                        <TableCell className={classes.Cell}>{formasDePago.filter(e => e.id === pago.formaDePago)[0].label}</TableCell>
                                        <TableCell className={classes.Cell}>{pago.comprobante}</TableCell>
                                        <TableCell className={classes.Cell}>{m(pago.importeAbonado)}</TableCell>
                                        <TableCell className={`${classes.Cell} ${estadoPagoStyle}`}>{estadoPago.label.toUpperCase()}</TableCell>
                                        {
                                            estadoPago.id === "PENDIENTE_VERIFICACION" ? 
                                            <TableCell className={`${classes.Cell} ${classes.CellIconos}`}>
                                                {props.cargandoCambiarEstadoPago ? <Spinner centered/> :
                                                <React.Fragment>
                                                    <Tooltip title="Rechazar pago" arrow>
                                                        <IconButton aria-label="Rechazar Pago" size="small" onClick={() => {props.rechazarPago(ufData.id, pago.uuid);}}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Verificar pago" arrow>
                                                        <IconButton aria-label="Verificar Pago" size="small" onClick={() => {props.verificarPago(ufData.id, pago.uuid);}}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>}
                                            </TableCell> : <TableCell className={classes.Cell}></TableCell>
                                        }
                                        {pago.observacionAviso || pago.observacionEstado ? 
                                            <TableCell className={classes.Cell}>
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        {pago.observacionAviso ? (<div><b><u>Observaciones propietario</u>:</b><br/>{pago.observacionAviso}<p/></div>) : ""}
                                                        {pago.observacionEstado ? (<div><b><u>Observaciones administrador</u>:</b><br/>{pago.observacionEstado}</div>) : ""}
                                                    </React.Fragment>} arrow textoNormal>
                                                    <IconButton aria-label="Observaciones" size="small" >
                                                        <ChatIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell> :  <TableCell className={classes.Cell}>
                                                                <Tooltip title="Sin observaciones" arrow textoNormal>
                                                                    <IconButton aria-label="Sin observaciones" size="small">
                                                                        <SpeakerNotesOffIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                        }
                                    </TableRow>
                                )}) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow> : null}
        </React.Fragment>
}