import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        isNumericString
        prefix="$"
        decimalSeparator=","
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

 const PlataField = (props) => (
    <TextField name={props.name}
        InputProps={{
            inputComponent: NumberFormatCustom,
        }}
        variant="outlined" 
        value={props.value}
        onChange={(event) => props.onChange(event)}
        {...props}
        />
)

PlataField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PlataField;