export const LANDING = '/';
export const REGISTRAR = '/registrarse';
export const INGRESAR = '/ingresar';
export const HOME = '/home';
export const CUENTA = '/cuenta';
//export const CONSORCIO = '/consorcios';
//export const CONSORCIO_ID = CONSORCIO + '/:consorcioId';
export const EXPENSAS = '/expensas';
export const EXPENSAS_EDIFICIO_ID = EXPENSAS + '/edificio/:edificioId';
export const EXPENSAS_ID = EXPENSAS + '/:expensaId';
export const ADMINISTRADOR = '/administrador';
export const SUPER_ADMIN = '/superadmin';
export const RECUPERAR = '/recuperar';
export const EMPADRONAR_EDIFICIO = '/qr/:consorcio/';
export const QR = '/qr/:consorcio/:uf';
export const SALIDA = '/logout';
export const TOKEN = '/token'
export const COMUNICACIONES = '/comunicaciones'
export const COBRANZA = '/cobranza'
export const COBRANZA_ID_EDI = COBRANZA +'/edificio/:edificioId'
export const COBRANZA_ID_EDI_EXP = COBRANZA_ID_EDI +'/expensa/:expensaId'
