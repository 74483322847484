import React from "react";
import SectionHeader from "components/liquidacion/secciones/sectionHeader/sectionHeader";
import GrupoGasto from "components/liquidacion/grupoGasto/grupoGasto";

export default (props) => (
    <React.Fragment>
        <SectionHeader header={"PAGOS DEL PERIODO POR SUMINISTROS, SERVICIOS, ABONOS Y SEGUROS"}/>

        {props.grupos.map((grupo, grupoId) => {
            return (<GrupoGasto 
                headers={props.headers} 
                {...grupo}
                key={grupoId} />);
        })}
    </React.Fragment>
);
