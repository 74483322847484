import React, { useEffect, useState } from "react";
import NavigationItem from "./navigationItem";
import classes from "./navigationItems.module.css";
import * as ROUTES from 'constants/routes';
import getMenues from "axios/getMenues";

const NavigationItems = (props) => {
	const {data, ejecutar} = getMenues();
	const [menuUsuario, setMenuUsuario] = useState(null);
	useEffect(() => {
		if (props.isAuth && (!sessionStorage.getItem("menues") || new Date().getTime() > sessionStorage.getItem("menues_expiration"))) {
			ejecutar();
		}
	}, [props.isAuth, ejecutar]);
	useEffect(() => {
		if (data) {
			sessionStorage.setItem("menues", JSON.stringify(data));
			sessionStorage.setItem("menues_expiration", new Date().getTime() + 1800000); //1.800.000ms = 30'
			setMenuUsuario(data);
		}
		else {
			setMenuUsuario(JSON.parse(sessionStorage.getItem("menues")));
		}
	}, [setMenuUsuario, data])
	var menu;
	if (props.isAuth) {
		menu = 
		(<React.Fragment>
			{menuUsuario ? menuUsuario.map((menu, i) => {
				return <NavigationItem 
					link={menu.link}
					onClick={props.onClick} key={i}>
						{menu.titulo}
				</NavigationItem>
			}) : null}
			<NavigationItem 
				link={ROUTES.EXPENSAS}
				onClick={props.onClick} >
					Expensas
			</NavigationItem>
			<NavigationItem 
				link={ROUTES.SALIDA}
				onClick={props.onClick} >
					Cerrar sesión
			</NavigationItem>
		</React.Fragment>);
	}
	else {
		menu = 
			(<React.Fragment>
				<NavigationItem 
					link={ROUTES.INGRESAR}
					onClick={props.onClick} 
					exact>
						Ingresar
				</NavigationItem>
			</React.Fragment>)
	}
    return (
		<ul className={classes.NavigationItems}>
			{menu}
		</ul>
	);
}

export default NavigationItems;
