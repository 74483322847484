import React from "react";
import classes from "./gasto.module.css";
import {IterarImportesKV, getDiv} from "utils/iterarImportes";
import PropTypes from 'prop-types';
import calcularGrid from "utils/calcularGrid";

const Gasto = (props) => {
	return (
		<div className={classes.GastoRow} style={calcularGrid(props.cantidadColumnas)}>
			<div>{props.descripcion}</div>
			{IterarImportesKV(props.importes, classes.Importe, props.cantidadColumnas)}
		</div>
	);
}

Gasto.propTypes = {
    descripcion: PropTypes.string.isRequired
}

const DetalleSueldo = (props) => {
	return (
		<div className={classes.GastoRow} style={calcularGrid(props.cantidadColumnas)}>
			<div>{props.descripcion}</div>
			{getDiv(classes.Importe, props.importe, 0)}
		</div>
	);
}

DetalleSueldo.propTypes = {
    descripcion: PropTypes.string.isRequired
}

export const DetalleFinanciero = (props) => {
	var fila = [];
	var position = 0;
	for (let index = 0; index < props.cantidadColumnas; index++) {
		var dato = props.datos[position];
		if (dato && dato.key === index) {
			fila.push(
				<div className={position !== 0 ? classes.Importe : null} 
					key={index} style={{fontWeight: dato.titulo ? "bold" : "normal", color: dato.titulo ? "var(--font-color)" : "inherit"}}>
						{dato.value}
				</div>)
			position++;
		}
		else 
			fila.push(<div className={classes.Importe} key={index}>-</div>)
	}
	return (
		<div className={classes.GastoRow} style={calcularGrid(props.cantidadColumnas - 1)}>
			{fila}
		</div>
	);
}

export default Gasto;
export {DetalleSueldo};
