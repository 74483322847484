import React from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import "./dateTimePicker.css"

export default (props) => {

    return (props.hidden ? null : <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DateTimePicker
                    variant="outlined"
                    format="dd/MM/yyyy HH:mm"
                    margin="normal"
                    ampm={false}
                    id={props.id}
                    invalidDateMessage="Formato inválido."
                    minDateMessage="La fecha no puede ser antes"
                    label={props.label}
                    value={props.disabled ? null : props.value}
                    onChange={props.setValue}
                    minDate={props.minDate ? props.minDate : new Date("1900-01-01")}
                    disabled={props.disabled}
                    okLabel="Confirmar"
                    cancelLabel="Cancelar"
                    />
            </MuiPickersUtilsProvider>)
}
