import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./navigationItem.module.css";

const navigationItem = (props) => (
    <li className={classes.NavigationItem}>
        <NavLink 
                to={props.link} 
                activeClassName={props.neverActive ? null : classes.active} 
                exact={props.exact}
                onClick={props.onClick}>
            {props.children}
        </NavLink>
    </li>
);

export default navigationItem;
