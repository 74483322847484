import React from "react";
import m from "./moneda";

export const IterarImportesKV = (importes, clase, cantidadColumnas) => {
	const importesDiv = [];
	var j = 0;
	for(var i = 0; i < cantidadColumnas; i++) {
		var importe;
		if(importes[j] && importes[j].key === i) {
			importe = importes[j].value;
			j++;
		}
		else {
			importe = null;
		}
		importesDiv.push(getDiv(clase, importe, i));
	}
	return importesDiv;
}

export const IterarImportes = (importes, clase, headers, cantidadColumnas) => {
	const importesDiv = [];
	for(var i = 0; i < cantidadColumnas; i++) {
		const text = headers ? headers[i] + "\n" : "";
		importesDiv.push(getDiv(clase, importes[i], i, text));
	}
	return importesDiv;
}

export const getDiv = (clase, importe, i, text = "") => {
	return (<div className={clase} key={i}>{text + m(importe)}</div>);
}
