import React from "react";
import { TextField } from "@material-ui/core";

export default (props) => {
    return <TextField   id={props.id}
                        label={props.label}
                        name={props.name}
                        variant="outlined" 
                        autoFocus={props.autoFocus}
                        multiline={props.multiline}
                        autoComplete="off"
                        InputProps={props.InputProps}
                        {...props}
                        />
}
