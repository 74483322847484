import React from "react";
import SectionHeader from "components/liquidacion/secciones/sectionHeader/sectionHeader";
import GrupoGasto from "./grupoGasto";

export default (props) => {
    return (props.estadoFinanciero && props.estadoFinanciero.length > 0 ? 
    (<React.Fragment>
        <SectionHeader header={"ESTADO FINANCIERO"}/>
        {props.estadoFinanciero.map((tabla, i) => {
            return <GrupoGasto {...tabla} key={i}/>
        })}
    </React.Fragment>) : null);
}
