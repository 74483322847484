import React from "react";
import f from "utils/fecha";
import classes from "./Cobranza.module.css";
import FilaUf from "./FilaUf";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

export default (props) => {
    const { data, setUf, setDialogoOpen, verificarPago, rechazarPago, cargandoCambiarEstadoPago, pagosHabilitados } = props;
    var colSpan = data[0].vencimientos.length + 7;
    return (
        <TableContainer className={classes.Table}>
            <Table>
                <TableHead>
                    <TableRow className={classes.Header}>
                        <TableCell>U.F.</TableCell>
                        <TableCell>Piso</TableCell>
                        <TableCell>Dpto</TableCell>
                        <TableCell>Co-propietario</TableCell>
                        {data[0].vencimientos.map((venc, key) => <TableCell key={key}>Saldo al<br/>{f(venc)}</TableCell>)}
                        {pagosHabilitados ?
                            <React.Fragment>
                                <TableCell>Total Pagado</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell> 
                            </React.Fragment> 
                        : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                {data.map((ufData, i) => {
                    return <FilaUf 
                        ufData={ufData} 
                        colSpan={colSpan} 
                        key={ufData.id} 
                        i={i} 
                        setUf={setUf} 
                        setDialogoOpen={setDialogoOpen} 
                        verificarPago={verificarPago} 
                        rechazarPago={rechazarPago}
                        cargandoCambiarEstadoPago={cargandoCambiarEstadoPago}
                        pagosHabilitados={pagosHabilitados}/>
                })
                }
                </TableBody>
            </Table>
        </TableContainer>);
}