import { useCallback } from 'react';
import { Wrapper } from './API';

export default () => {
    var [cargando, data, error,, setURLYEjecutar] = Wrapper(false, "GET");
    const ejecutar = useCallback(() => {
        setURLYEjecutar("usuario/menues");
    }, [setURLYEjecutar])
    return {cargando, data, error, ejecutar};
}
