import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import * as ROUTES from "constants/routes";
import IngresoPage from "components/pages/ingreso";
import SalidaPage from "components/pages/salida";
import CuentaPage from "components/cuenta";
import { Expensas, Liquidacion } from "components/pages/consorcios";
import AdministradorPage from "components/pages/administrador";
import SuperAdminPage from "components/pages/superAdmin";
import QREmpadronamiento from "components/pages/qrEmpadronamiento/qrEmpadronamiento";
import { Auth } from "components/firebase/authContext";
import GestionComunicaciones from "components/pages/GestionComunicaciones/GestionComunicaciones";
import Cobranza from "components/pages/cobranza/Cobranza";
import Token from "components/pages/token/token";

export default React.memo(() => {
	const {usuario} = useContext(Auth);
    return (
			<Switch>
				{/*<Route exact path={ROUTES.LANDING} component={LandingPage}/>
				<PrivateRoute path={ROUTES.HOME} component={HomePage} authUser={usuario}/>*/}
				<PrivateRoute path={ROUTES.CUENTA} component={CuentaPage} authUser={usuario}/>
				<PrivateRoute path={ROUTES.EXPENSAS_EDIFICIO_ID} component={Expensas} exact authUser={usuario} />
				<PrivateRoute path={ROUTES.EXPENSAS} component={Expensas} exact authUser={usuario} />
				<PrivateRoute path={ROUTES.EXPENSAS_ID} component={Liquidacion} exact authUser={usuario} />
				<PrivateRoute path={ROUTES.ADMINISTRADOR} component={AdministradorPage} authUser={usuario} />
				<PrivateRoute path={ROUTES.SUPER_ADMIN} component={SuperAdminPage} authUser={usuario} />
				<PrivateRoute path={ROUTES.QR} component={QREmpadronamiento} authUser={usuario} />
				<PrivateRoute path={ROUTES.EMPADRONAR_EDIFICIO} component={QREmpadronamiento} authUser={usuario} />
				<PrivateRoute path={ROUTES.TOKEN} component={Token} authUser={usuario} />
				<PrivateRoute path={ROUTES.COMUNICACIONES} component={GestionComunicaciones} authUser={usuario} />
				<PrivateRoute path={ROUTES.COBRANZA_ID_EDI_EXP} component={Cobranza} authUser={usuario} />
				<PrivateRoute path={ROUTES.COBRANZA_ID_EDI} component={Cobranza} authUser={usuario} />
				<PrivateRoute path={ROUTES.COBRANZA} component={Cobranza} authUser={usuario} />
				<PrivateRoute path={ROUTES.SALIDA} component={SalidaPage} authUser={usuario} />
				{/*<Route exact path={ROUTES.LANDING} component={LandingPage} />*/}
				<Route exact path={ROUTES.INGRESAR} component={IngresoPage} />
				{/*<Redirect from={ROUTES.CONSORCIO_ID} to={ROUTES.EXPENSAS} />*/}
				<Redirect to={ROUTES.EXPENSAS} push/>
			</Switch>);
});

const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
    <Route {...rest} 
	key={Math.random()}
    render={props =>
		authUser ? (<Component {...props} />) : (<Redirect to={{ pathname: ROUTES.INGRESAR, state: { from: props.location } }}/>)
    }
    />
);
