import React from "react";
import classes from "./header.module.css";

const mapKeyValue = (data, dataType) => {
	return data.map((kv, index)=> {
		return (
			<div className={classes.Dataset} key={dataType + "_DS_"+index}>
				<span 	className={classes.Key} 
						key={dataType + "_K_"+index}>{(kv.value ? kv.key+":" : kv.key)}
				</span>
				{kv.value 
					? (<span className={classes.Value} 
							 key={dataType + "_V_"+index}>{kv.value}
					   </span>) 
					: null}
			</div>
		)
	});
}

const Header = (props) => {
	return (
		<div className={[classes.Header, classes.Card].join(" ")}>
			<div className={classes.ExpensaHeader}>
                <div className={classes.Titulo} key={"administracion"}>Administracion</div>
				{mapKeyValue(props.administrador, "AD")}
            </div>
			<div className={classes.ExpensaHeader}>
                <div className={classes.Titulo} key={"edificio"}>Edificio</div>
				{mapKeyValue(props.edificio, "ED")}
            </div>
        </div>
	)
}

export default Header;