import React from "react";
import { DetalleSueldo } from "../gasto/gasto";
import classes from "./sueldo.module.css"
import calcularGrid from "utils/calcularGrid";
import GastoHeader from "../gastoHeader/gastoHeader";

export default (props) => {
    var gridStyle = calcularGrid(props.headers.length + 1)
    return (<React.Fragment>
        <div className={classes.GrupoTitulos}>
            <div className={classes.RowTitulos} style={gridStyle}>
                <div className={classes.RowTitulosFlex}>
                    <div className={[classes.Titulo].join(" ")}>Cargo: <span>{props.sueldo.cargo}</span></div>
                    <div className={[classes.Titulo].join(" ")}>Categoria del Edificio: <span>{props.sueldo.catEdificio}</span></div>
                </div>
            </div>
            <div className={classes.RowTitulos} style={gridStyle}>
                <div className={classes.RowTitulosFlex}>
                    <div className={[classes.Titulo].join(" ")}>Periodo: <span>{props.sueldo.periodo}</span></div>
                    <div className={[classes.Titulo].join(" ")}>Categoria del Encargado: <span>{props.sueldo.catEncargado}</span></div>
                </div>
            </div>
        </div>

        <div className={[classes.Titulo, classes.Descripcion].join(" ")}>{props.soloHeader ? null : "Descripcion"}</div>
        {props.sueldo.detalle.map((detalle, i) => {
            return (
                <DetalleSueldo 
                descripcion={detalle.key} 
                importe={detalle.value} 
                cantidadColumnas={props.headers.length + 1} 
                key={i}/>);
        })}
        <GastoHeader headers={props.headers} soloHeader/>
    </React.Fragment>);
}
