import { Wrapper } from './API';

export default () => {
    const [cargando, data, error,, setURLYEjecutar, clear] = Wrapper(false, "POST");
    const ejecutar = (consorcioId, mostrarDesde, mostrarHasta, titulo, mensaje, comunicacionId) => {
        setURLYEjecutar(`comunicaciones/${consorcioId}/${comunicacionId ? comunicacionId : ""}`, 
        null, 
        {mostrarDesde: mostrarDesde, mostrarHasta: mostrarHasta, titulo: titulo, mensaje: mensaje});
    }
    return {cargando, data, error, ejecutar, clear};
}
