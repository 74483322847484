import React from "react";
import { Collapse, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";

export default (props) => {
    return  <Collapse in={props.children !== null} >
                <Alert variant="outlined" 
                        severity={props.severity} 
                        action={ props.onClose ? 
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                props.onClose();
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton> : null
                        }>{props.children}</Alert>
            </Collapse>
};
