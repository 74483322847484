import React from 'react';
import classes from "./periodoRow.module.css";
export default (props) => {
    return (<div className={classes.RowCard}>
        <div className={classes.RowCardTitle}>{props.titulo}</div>
        {props.aFavor ? <div className={classes.RowCardAFavor}>{props.valor}</div> : 
            <div className={props.overrideClassNameValor || classes.RowCardValue}>{props.valor}</div>}
        
    </div>);
}

export const PeriodoRowVencimiento = ({numVencimiento, fechaVencimiento, valor}) => {
    return (<div className={classes.RowCard}>
                <div className={classes.RowCardTitle}>
                    <div>{numVencimiento} vencimiento</div>
                    <div className={classes.RowCardVencimiento}>{fechaVencimiento}</div>
                </div> 
                <div className={classes.RowCardVencimientoValue}>{valor}</div>
            </div>);
}
