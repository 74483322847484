import React from "react";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import "./AutoComplete.module.css";

export default (props) => {
    return (<Autocomplete
        noOptionsText={"No hay coincidencias"}
        style={{ width: 400 }}
        onChange={(event, value) => {props.onChange(value)}}
        autoComplete={false}
        renderInput={(params) => <TextField {...params} variant="outlined" label={props.label} />}
        fullWidth
        autoSelect
        autoHighlight
        {...props}
    />)
}
