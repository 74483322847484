import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export default (props) => {
    return (
        <div>
        <Dialog open={props.open}
                TransitionComponent={Transition}
                onClose={props.handleCancelar}>
            {props.titulo ? <DialogTitle>{props.titulo}</DialogTitle> : null}
            <DialogContent>
                <DialogContentText>{props.mensaje}</DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions style={{padding: "0 24px 16px 24px"}}>
                <Button onClick={props.handleCancelar} color="secondary" variant="outlined">
                    {props.iconoCancelar} Cancelar
                </Button>
                <Button onClick={props.handleConfirmar} color="primary" variant="outlined">
                    {props.iconoConfirmar} Confirmar
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
  