import axios from 'axios';
import { useContext, useEffect, useState, useCallback} from "react";
import { Auth } from "components/firebase/authContext";

const API = axios.create({
  baseURL: process.env.REACT_APP_LIQUIDAR_API
});

const Wrapperv2 = (arrancaCargando, method, url) => {
  const [methodAEjecutar] = useState(method);
  const [urlAEjecutar, setUrlAEjecutar] = useState(url);
  const [cargando, setCargando] = useState(arrancaCargando);
  const [getArrancaCargando] = useState(arrancaCargando);
	const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [ejecutar, setEjecutar] = useState(false);
  const [callback, setCallback] = useState(null);
  const { usuario } = useContext(Auth);
  const setEjecutarTrue = () => {
    setEjecutar(true);
  }

  const run = useCallback(async(urlAEjecutar, methodAEjecutar, usuario, callback, setCargando, setData, setError, data) => {
      setCargando(true);
      const token = await usuario.getIdToken(false);
      var resData;
      var error;
      await API.request({url: urlAEjecutar, method: methodAEjecutar, headers: {token: token}, data: data})
        .then(res => {
            if (process.env.NODE_ENV !== 'production')
              console.log(res.config.url, res);
            setData(res.data);
            resData = res.data;
        })
        .then(() => {
          if (callback)
            callback();
        })
        .catch(err => {
            console.error(err.config.url, err.response);
            setError(err.response);
            error = err.response;
        })
        .finally(() => {
          setCargando(false);
        });
        return {"data": resData, "error": error}
    }, []);

  const setURLYEjecutar = useCallback(async(newURL, then, data) => {
    if (process.env.NODE_ENV !== 'production')
      console.log(newURL);
    if (then)
      setCallback(() => then);
    setUrlAEjecutar(newURL);
    return await run(newURL, methodAEjecutar, usuario, then, setCargando, setData, setError, data);
  }, [run, methodAEjecutar, usuario])

  useEffect(() => {
      if (!ejecutar)
          return;
      run(urlAEjecutar, methodAEjecutar, usuario, callback, setCargando, setData, setError, null);
  }, [usuario, ejecutar, methodAEjecutar, urlAEjecutar, getArrancaCargando, callback, run]);

  const clear = useCallback(() => {
    setCargando(false);
    setData(null);
    setError(null);
  }, []);

  return {"cargando": cargando, "data": data, "error": error, "setEjecutarTrue": setEjecutarTrue, "setURLYEjecutar": setURLYEjecutar, "clear": clear};
};

export default API;
export {Wrapperv2};
