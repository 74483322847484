import * as firebaseui from "firebaseui";
import * as routes from "constants/routes";
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const uiConfig = {
	signInFlow: 'popup',
	signInSuccessUrl: routes.EXPENSAS,
	signInOptions: ["google.com", "facebook.com", "yahoo.com", "password"],
	credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

export { config, uiConfig };
