import React, { useEffect, useState } from "react";
import TextField from "components/ui/textField/textField";
import Dialog from "components/ui/Dialog/Dialog";

export default (props) => {
	const [observacion, setObservacion] = useState("");
    const {params} = props;

    useEffect(() => {
        setObservacion("");
    }, [params])

    return  <Dialog open={params !== null ? true : false} 
                    handleCancelar={() => props.setParams(null)} 
                    handleConfirmar={() => props.modificarEstadoPago(observacion)}
                    titulo={`¿Confirmas que ${params && params.estado === "aprobar" ? "aprobas" : "rechazas"} el pago?`}
                    mensaje={`Se enviará un correo electrónico al propietario informando que su pago fue 
                        ${params && params.estado === "aprobar" ? "aprobado" : "rechazado"}, junto con la observación en caso de que haya.`}>
                <p>Esto acción no puede deshacerse.</p>
                <br/>
                <TextField label="Observacion" 
                    value={observacion}
                    onChange={(event) => {setObservacion(event.target.value)}}
                    autoFocus/>
            </Dialog>

}   