import React from "react";
import logoPiscis from "logo.svg";
import classes from "./logo.module.css";
import { NavLink } from "react-router-dom";

const logo = () => (
    <NavLink 
        className={classes.Logo}
        to={"/"} 
        exact>
            <img src={logoPiscis} alt="Logo Piscis" />
    </NavLink>
)

export default logo;
