import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Spinner from "components/ui/spinner"
import Button from '@material-ui/core/Button';
import { doEmpadronamientoRequest } from "axios/doEmpadronamiento";
import getUfs from "axios/getUfs";
import "./DialogoAsociarUF.css";

export default (props) => {
    const [valorAsociarUf, setValorAsociarUf] = useState("");
	const [, dataUfs,, ejecutarUfs] = getUfs();
    const [cargandoAsociacionUfs,,, ejecutarAsociacionUfs] = doEmpadronamientoRequest();

    const onEnter = () => {
        if (valorAsociarUf !== "" || dataUfs == null)
            ejecutarUfs(props.consorcioId);
        setValorAsociarUf("");
    };
    const handleClose = () => {
        setValorAsociarUf("");
        props.onClose();
    };
    const onChangeValorAsociarUf = (event, valor) => {
        setValorAsociarUf(valor);
    };
    const handleAceptar = () => {
        if (valorAsociarUf !== "") {
            ejecutarAsociacionUfs(props.consorcioId, valorAsociarUf, () => props.then());
        }
        props.onClose();
    }
    return <Dialog open={props.open} onEnter={onEnter} onClose={handleClose} aria-labelledby="form-dialog-title" id="DialogoAsociarUF">
        <DialogTitle className="form-dialog-title">Asociar unidad funcional</DialogTitle>
        <DialogContent>
            {(props.open && dataUfs == null) || cargandoAsociacionUfs ? (<Spinner centered />) : 
            (<React.Fragment>
                <DialogContentText className="form-dialog-text">
                    ¿Qué unidad funcional querés asociar?
                </DialogContentText>
                <Autocomplete
                    id="combo-box-demo"
                    options={dataUfs ? dataUfs : []}
                    style={{ width: 300 }}
                    onChange={onChangeValorAsociarUf}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    noOptionsText={"No hay coincidencias"}
                />
            </React.Fragment>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} className="botonCancelar">
                Cancelar
            </Button>
            {dataUfs != null ? <Button onClick={handleAceptar} className="botonAceptar">
                Agregar
            </Button> : null}
        </DialogActions>
    </Dialog>
}
