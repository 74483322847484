import React from "react";
import Spinner from "components/ui/spinner"
import { FIREBASE_NO_ENCONTRADO, SERVER_ERROR } from "constants/mensajes";
import Mensaje from "components/ui/mensaje/mensaje"

export default (props) => {
	if (props.error !== null) {
        if (props.error === undefined)
            return <Mensaje>{SERVER_ERROR}</Mensaje>;
        if (props.error.status === 404) {
            return props.onNull();
        }
        if (props.onError)
            return props.onError;
        return (props.error.status);
    }
	if (props.cargando)
        return (<Spinner centered />);

	if (props.data === null) {
        if (props.onNull)
            return props.onNull();
        else
            return (<Mensaje>{FIREBASE_NO_ENCONTRADO}</Mensaje>);
    }
    
    if (props.onSuccess)
        return props.onSuccess();
        
    return null;
};
