import React, { useEffect, useState } from "react";
import QueryMiddleState from "components/queryMiddleState/queryMiddleState";
import './expensas.css';
import getExpensas from "axios/getExpensas";
import getExpensasPDFs from "axios/getExpensasPDFs";
import {onSuccessPDFs, onNullPdfs} from "./componentes/tablaPdfs";
import {onSuccessUfs, onNullUfs} from "./componentes/ufs";
import ConsorciosDropDown from "./componentes/ConsorciosDropDown";
import BotonAsociarUF from "./componentes/BotonAsociarUF";
import { TablaComunicacionesUsuario } from "components/TablaComunicaciones";
import { EXPENSAS_EDIFICIO_ID } from "constants/routes";
import Dialogo from "components/pages/cobranza/Dialogo";

export default React.memo((props) => {
	const [consorcioId, setConsorcioId] = useState(props.match.params.edificioId ? props.match.params.edificioId : "");
	const [cargandoExp, dataExp, errorExp, ejecutarExp] = getExpensas();
	const [cargandoPDFs, dataPDFs, errorPDFs, ejecutarPDFs] = getExpensasPDFs();
	const [dialogoPagoOpen, setDialogoPagoOpen] = useState(false);
	const [dataUfs, setDataUfs] = useState([]);
	const [uf, setUf] = useState("");

	useEffect(() => {
		if (consorcioId === "")
			return;
		ejecutarExp(consorcioId);
		ejecutarPDFs(consorcioId);
	}, [consorcioId, ejecutarExp, ejecutarPDFs])

	const seleccionarConsorcio = (consorcioId) => {
        window.history.replaceState(null, "", EXPENSAS_EDIFICIO_ID.replace(":edificioId", consorcioId));
		setConsorcioId(consorcioId);
	}

    const toggleDialogoPago = (uf) => {
        setUf(uf);
        setDialogoPagoOpen(!dialogoPagoOpen);
    }

	useEffect(() => {
		setDataUfs(dataExp);
	}, [setDataUfs, dataExp])

	const setData = (data) => {
		dataUfs.ufs = data;
		setDataUfs(dataUfs);
	}
	return (
		<React.Fragment>
			<ConsorciosDropDown consorcioId={consorcioId} setConsorcioId={(consorcioId) => seleccionarConsorcio(consorcioId)} />
			<BotonAsociarUF consorcioId={consorcioId} then={() => ejecutarExp(consorcioId)}/>
			<TablaComunicacionesUsuario consorcioId={consorcioId}/>
			<Dialogo open={dialogoPagoOpen} onClose={() => setDialogoPagoOpen(false)} uf={uf} propietario 
			dataUfs={dataUfs ? dataUfs.ufs : []} setData={setData}/>

			<div className="main">
				{QueryMiddleState(errorExp, cargandoExp, dataUfs, 
					() => {return onNullUfs()}, 
					() => {return onSuccessUfs(dataUfs, toggleDialogoPago)})}
				{QueryMiddleState(errorPDFs, cargandoPDFs, dataPDFs, 
					() => onNullPdfs(consorcioId), 
					() => {return onSuccessPDFs(dataPDFs)})}
			</div>
		</React.Fragment>
	)
});
