import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";

export default (props) => {
	const {data, seleccionarPrimerValor, setId, propiedadComoId, value, id, getValorMostrar} = props;

	useEffect(() => {
		if (seleccionarPrimerValor && data.length > 0 && value === ""){
            setId(data[0][propiedadComoId])}
	}, [data, seleccionarPrimerValor, propiedadComoId, value, setId])

	return <div id={id + "Container"}>
			<FormControl>
				<InputLabel id={id + "Label"}>¿Qué liquidación te gustaría ver?</InputLabel>
				<Select
					labelId={id + "Label"}
					id={id}
					value={value}
					defaultValue={value}
					onChange={(event) => setId(event.target.value)}>
					{data.map((valor) => {
						return <MenuItem value={valor["id"]} key={valor["id"]}>{getValorMostrar(valor)}</MenuItem>
					})}
				</Select>
			</FormControl>
		</div>
}
