import React, { useState } from "react";
import ConsorciosDropDown from "components/pages/consorcios/expensas/componentes/ConsorciosDropDown";
import classes from "./GestionComunicaciones.module.css";
import { TablaComunicacionesAdministrador } from "components/TablaComunicaciones";

export default () => {
	const [consorcioId, setConsorcioId] = useState("");

	return (
		<React.Fragment>
            <div className={classes.ConsorciosDropDown}>
                <ConsorciosDropDown consorcioId={consorcioId} 
                                    setConsorcioId={(consorcioId) => setConsorcioId(consorcioId)} 
                                    leyenda={"¿Para qué edificio?"}
                                    esAdministrador
                                    />
            </div>
			<TablaComunicacionesAdministrador consorcioId={consorcioId} />
        </React.Fragment>
	)
};
