import React from "react";
import classes from "./subtotal.module.css";
import {IterarImportes} from "utils/iterarImportes";
import PropTypes from 'prop-types';

const Subtotal = (props) => {
	var gridTemplateColumnsValue = "auto";
	var columnasAGenerar = props.expanded ? props.headers.length : props.headers.length - 1;
	for (let index = 0; index < columnasAGenerar; index++) {
		gridTemplateColumnsValue += " var(--importe-size)";
	}
	return (
		<div className={props.expanded ? classes.GastoRow : classes.SubtotalesCollapsed} style={{gridTemplateColumns: gridTemplateColumnsValue}}>
			{props.expanded ? (<div className={classes.SubtotalDescripcion}>Subtotales:</div>) : null}
			{IterarImportes(props.importes, 
							props.expanded ? classes.Importe : classes.Subtotal, 
							props.expanded ? null : props.headers,
							props.headers.length)}
		</div>
	);
}

Subtotal.propTypes = {
    importes: PropTypes.arrayOf(PropTypes.number),
	expanded: PropTypes.bool.isRequired,
	headers: PropTypes.arrayOf(PropTypes.string)
}

export default Subtotal;
