import React, { useCallback, useEffect } from "react";
import QueryMiddleState from "components/queryMiddleState/queryMiddleState";
import getConsorcios from "axios/getConsorcios";
import Mensaje from "components/ui/mensaje/mensaje";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import opcionesAsociar from "./opcionesAsociar";

export default React.memo((props) => {
	const consorcios = getConsorcios();
    const {setConsorcioId} = props;
    const setConsorcio = useCallback((consorcioId) => {
		setConsorcioId(consorcioId);
    }, [setConsorcioId]);
	const {ejecutar} = consorcios;
	useEffect(() => {
		ejecutar(props.esAdministrador);
    }, [ejecutar, props.esAdministrador]);

    useEffect(() => {
        if (consorcios.data && consorcios.data.length === 1 && props.consorcioId !== consorcios.data[0].id) {
            setConsorcio(consorcios.data[0].id);
        }
    }, [consorcios.data, props.consorcioId, setConsorcio]);

	return QueryMiddleState(consorcios.error, consorcios.cargando, consorcios.data, () => {}, 
        () => {return OnSuccessConsorcios(consorcios.data, setConsorcio, props.consorcioId, props.leyenda)});
});


const OnSuccessConsorcios = (data, setConsorcio, consorcioId, leyenda) => {
	if (data.length === 0) {
		return  <Mensaje>
					<p>¡Uy! No tenes ningun edificio registrado todavía. Pero no te preocupes, es muy sencillo asociarlo.</p>
					{opcionesAsociar}
				</Mensaje>
	}
	return (<React.Fragment>
		<div id="selectEdificioUF">
			<FormControl>
				<InputLabel id="selectEdificioLabel">{leyenda ? leyenda : "¿Qué edificio te gustaría ver?"}</InputLabel>
				<Select
					labelId="selectEdificioLabel"
					id="selectEdificio"
					value={consorcioId}
					onChange={(event) => setConsorcio(event.target.value)}>
					{data.map((consorcio, key) => {
						return <MenuItem value={consorcio.id} key={key}>{consorcio.direccion}</MenuItem>
					})}
				</Select>
			</FormControl>
		</div>
	</React.Fragment>)
}
