import { Button } from "@material-ui/core";
import React, { useState } from "react";
import DialogoAsociarUF from "./DialogoAsociarUF";
import classes from "./BotonAsociarUF.module.css";

export default (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    
    if (!props.consorcioId) {
        return null;
    }

    return  <div className={classes.Wrapper}>
                <Button variant="outlined" color="primary" id="agregarUFLink" onClick={() => setDialogOpen(true)}>
                    <b>Asociar unidad funcional</b>
                </Button>
                <DialogoAsociarUF open={dialogOpen} onClose={() => setDialogOpen(false)} consorcioId={props.consorcioId} then={props.then}/>
            </div>
}
