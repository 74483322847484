import React from "react";
import classes from "./spinner.module.css";

const Spinner = (props) => (
    <div className={[classes.SpinnerContainer, props.centered ? classes.Centered : classes.BottomRight].join(" ")}>
        <div className={classes.Spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Spinner;