import React from "react";
import classes from "./Cobranza.module.css";
import FilaUfMobile from "./FilaUfMobile";

export default (props) => {
    const { setUf, setDialogoOpen, verificarPago, rechazarPago, cargandoCambiarEstadoPago, pagosHabilitados } = props;
    return <div className={classes.TableMobile}>
        {props.data.map((uf, keyRow) => {
            return (
                <FilaUfMobile   uf={uf} 
                                key={keyRow} 
                                setUf={setUf} 
                                setDialogoOpen={setDialogoOpen}
                                verificarPago={verificarPago} 
                                rechazarPago={rechazarPago}
                                cargandoCambiarEstadoPago={cargandoCambiarEstadoPago}
                                pagosHabilitados={pagosHabilitados}/>
            )})
        }
    </div>
};