import React, { useState } from "react";
import classes from "./grupoGasto.module.css";
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade/Fade';
import { DetalleFinanciero } from "components/liquidacion/gasto/gasto";
import {CuadroHeader} from "components/liquidacion/gastoHeader/gastoHeader";

const GrupoGasto = (props) => {
	const [expanded, setExpanded] = useState(!props.expanded && !props.sueldo);
	const clickCollapse = () => {
		setExpanded(!expanded);
	}
	const titulo = (<div className={classes.Titulo} onClick={() => clickCollapse()}>
						<div className={expanded ? classes.ArrowUp : classes.ArrowDown} />
						<div>{props.titulo}</div>
					</div>);
	/*useEffect(() => {
		window.onscroll = () => {
			//console.log(window.pageYOffset);
		}
	}, []);*/
	return 	(
				<div className={classes.Grupo}>
					{titulo}
					<Collapse in={expanded} onClick={()=>clickCollapse()} timeout={500} unmountOnExit>
						<CuadroHeader headers={props.headers}/>
						{props.datos.map((dato, i) => {
						return (<DetalleFinanciero  
										datos={dato} 
										cantidadColumnas={props.headers.length} 
										key={i}
										/>);
						})}
					</Collapse>
					<Fade in={!expanded} className={!expanded ? classes.Collapsed : classes.Hidden} timeout={700} unmountOnExit>
						<div></div>
					</Fade>
				</div>
	)
}

GrupoGasto.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default GrupoGasto;
